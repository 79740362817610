const MoreSvgIcon = ({ isActive }) => {
  return (
    <>
      <svg
        className="moreHoverIcon"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="21"
          fill={isActive ? '#FFA735' : '#000'}
          fill-opacity={isActive ? '1' : '0.3'}
          stroke="#FFA735"
          strokeWidth="2"
        />
        <circle cx="22" cy="16" r="2" fill={isActive ? '#000' : '#FFA735'} />
        <circle cx="22" cy="22" r="2" fill={isActive ? '#000' : '#FFA735'} />
        <circle cx="22" cy="28" r="2" fill={isActive ? '#000' : '#FFA735'} />
      </svg>

      <style>
        {`
         svg.moreHoverIcon:hover circle{
            fill: #FFA735;
            fill-opacity: 1
          }
          svg.moreHoverIcon:hover circle:nth-of-type(2)
          {
            fill: #000;
          }
          svg.moreHoverIcon:hover circle:nth-of-type(3) {
            fill: #000;
          } 
          svg.moreHoverIcon:hover circle:nth-of-type(4) {
            fill: #000;
          } 
          
        `}
      </style>
    </>
  );
};

export default MoreSvgIcon;
