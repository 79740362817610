/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SectionUI from '@msr/Common/SectionUI';

/* Services */

const ImportantMessageFlexible = ({ sectionContent, region, lang }) => {
  return (
    <Box
      as="div"
      display="block"
      bgColor="#F9EFDC"
      p="40px"
      borderRadius="10px"
      className="colorChanges"
      my="40px"
    >
      {sectionContent?.content && sectionContent.content?.length
        ? sectionContent?.content?.map((secCon, i) => {
            return (
              <SectionUI
                key={i}
                sectionContent={secCon}
                region={region}
                language={lang}
              />
            );
          })
        : null}
      <style jsx global>
        {`
          .colorChanges .structured-content-light h1,
          .colorChanges .structured-content-light h2,
          .colorChanges .structured-content-light h3,
          .colorChanges .structured-content-light h4,
          .colorChanges .structured-content-light h5,
          .colorChanges .structured-content-light h6,
          .colorChanges .structured-content-light p,
          .colorChanges .structured-content-light p span,
          .colorChanges .structured-content-light p strong {
            color: #000 !important;
          }
          .colorChanges .structured-content-light ol,
          .colorChanges .structured-content-light ul,
          .colorChanges .structured-content-light li,
          .colorChanges .structured-content-light li p,
          .colorChanges .structured-content-light li a {
            color: #000 !important;
          }
          @media (max-width: 600px) {
            .colorChanges {
              padding: 16px 0px !important;
              margin: 10px auto !important;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default ImportantMessageFlexible;
