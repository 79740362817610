/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
import config from '@config';

/* Services */

/**
 * Renders the SoiMusicHorizontalCard Card component
 * @param {Object} sectionContent - Data for section
 * @returns {ReactElement} SoiMusicHorizontalCard Card component
 */

const SoiMusicHorizontalCard = sectionContent => {
  const objArrows = {
    pos: 'absolute',
    top: '98%',
    zIndex: '10',
    cursor: 'pointer',
    bgSize: '20px',
    w: '46px',
    h: '46px',
    boxShadow: '0 0 7px 0 rgb(0 0 0 / 13%)',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    color: 'transparent',
  };
  const NextArrow = props => {
    const { onClick } = props;

    return (
      <Box
        {...objArrows}
        bgImage={`url(${config.imgPath}/d/46272/1664373600-vector-2.png)`}
        right={{ base: '-10px', md: '320px' }}
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      ></Box>
    );
  };
  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        {...objArrows}
        bgImage={`url(${config.imgPath}/d/46272/1664373605-vector-3.png)`}
        left={{ base: '-10px', md: '320px' }}
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      />
    );
  };
  return (
    <Box mx="auto" w="full">
      <Box
        px={{ base: '0', md: '50px' }}
        py="20px"
        maxW={{ base: '100%', lg: '1210px' }}
        w="full"
        mx="auto"
      ></Box>
      <style jsx global>
        {`
          .slick-slide {
            margin: 0;
          }
          .slick-list {
            margin: 0 -22px;
          }
          @media (max-width: 767px) {
            .slick-slide {
              margin: 0px;
            }
            .slick-list {
              margin: 0;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default SoiMusicHorizontalCard;
