/* Built In Imports */
/* External Imports */
import { Heading } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the TvChannelsSubtitle FieldId component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} TvChannelsSubtitle FieldId.
 */

const TvChannelsSubtitle = ({
  sectionContent
}) => {
  return (
    <MidContainer w={'586px'}>
      <Heading
        as="h4"
        fontWeight="500"
        fontSize={{ base: '20px', md: '26px' }}
        color="#fff"
        pb={{ base: '30px', md: '40px' }}
        fontFamily="FedraSansStd-medium"
        textAlign="center"
      >
        {sectionContent?.text}
      </Heading>
    </MidContainer>
  );
};

export default TvChannelsSubtitle;
