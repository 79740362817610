const DownloadSvgIcon = ({ isActive }) => {
  const color = '#FFA735';
  return (
    <>
      <svg
        className="hoverIcon"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="21"
          fill={isActive ? '#FFA735' : '#000'}
          fill-opacity={isActive ? '1' : '0.3'}
          stroke="#FFA735"
          stroke-width="2"
        />
        <path
          d="M28.2992 24.1031V24.9431C28.2992 26.1193 28.2992 26.7073 28.0703 27.1565C27.869 27.5517 27.5478 27.8729 27.1526 28.0742C26.7034 28.3031 26.1154 28.3031 24.9392 28.3031H19.0592C17.8831 28.3031 17.2951 28.3031 16.8458 28.0742C16.4507 27.8729 16.1294 27.5517 15.9281 27.1565C15.6992 26.7073 15.6992 26.1193 15.6992 24.9431V24.1031M25.4992 20.6031L21.9992 24.1031M21.9992 24.1031L18.4992 20.6031M21.9992 24.1031V15.7031"
          stroke={isActive ? '#000' : '#FFA735'}
          stroke-width="1.4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <style>
        {`
         svg.hoverIcon:hover circle{
            fill: #FFA735;
            fill-opacity: 1
          }
          svg.hoverIcon:hover path {
            stroke:#000 !important;
          }
        `}
      </style>
    </>
  );
};

export default DownloadSvgIcon;
