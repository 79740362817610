/* Built In Imports */
import { useState } from 'react';
/* External Imports */
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/**
 *
 * @param root0
 * @param root0.card
 * @param root0.index
 * @param root0.sectionContent
 * @returns
 */
const MsrEventScheduleCard = ({ card, index, sectionContent }) => {
  const [isArrow, setIsArrow] = useState(false);
  const [isLargerThan480] = useMediaQuery('(min-width: 480px)');

  return (
    <>
      {index === 0 || index === (sectionContent?.scheduleCards?.length - 1) ? (
        <Flex gridGap="18px" mx="15px">
          <Box display="flex" flexBasis="10">
            <Box
              position="relative"
              zIndex={2}
              _after={{
                borderLeft: index === (sectionContent?.scheduleCards?.length - 1) ? 'none' : '4px dashed #383D79',
                content: '""',
                position: 'absolute',
                top: '5%',
                left: '11px',
                height: '100%',
                zIndex: '-1',
              }}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14" cy="14" r="7" fill="#FFA735" />
                <circle
                  cx="14"
                  cy="14"
                  r="12"
                  stroke="#FFA735"
                  strokeWidth="3"
                />
              </svg>
            </Box>
          </Box>
          <Box display="flex" width="100%" mb="22px">
            <Box
              width="100%"
              bgColor="blue.600"
              borderRadius="3px"
              py={{ base: '20px', md: '30px' }}
              px={{ base: '18px', md: '20px', xl: '40px' }}
              position="relative"
            >
              <Box position="absolute" top="10px" left="-12px">
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-3.71547e-07 7.5L13.4368 0.138784L13.4368 14.8612L-3.71547e-07 7.5Z"
                    fill="#252964"
                  />
                </svg>
              </Box>

              <Text
                color="yellow.100"
                fontSize={{ base: '18px', md: '26px' }}
                pt="0"
                mt="0"
                fontFamily="FedraSansStd-book"
                fontWeight="400"
                lineHeight="1.2"
              >
                {card?.time}
              </Text>
              <Text
                color="white.50"
                fontSize={{ base: '18px', md: '26px' }}
                pt="10px"
                mt="0"
                fontFamily="FedraSansStd-A-medium"
                lineHeight="1.2"
              >
                {card?.title}
              </Text>
              {isLargerThan480 && (
                <>
                  <Box color="blue.200">
                    <StructuredTextParser
                      str={render(card?.description)}
                      fontFamily="FedraSansStd-book"
                    />
                  </Box>
                  {render(card?.highlightContent) &&
                    render(card?.highlightContent) !== '<p></p>' && (
                      <Box color="white.50" bgColor="#7073A1">
                        <StructuredTextParser
                          str={render(card?.highlightContent)}
                          fontFamily="FedraSansStd-medium"
                          textAlign="center"
                        />
                      </Box>
                    )}
                </>
              )}
            </Box>
          </Box>
        </Flex>
      ) : (
        <Flex gridGap="18px" mx="15px">
          <Box display="flex" flexBasis="10">
            <Box
              position="relative"
              zIndex={2}
              _after={{
                borderLeft: '4px dashed #383D79',
                content: '""',
                position: 'absolute',
                top: '6%',
                left: '11px',
                height: '100%',
                zIndex: '-1',
              }}
            ></Box>
          </Box>
          <Box display="flex" width="100%" mb="22px">
            <Box
              bgColor="blue.600"
              borderRadius="3px"
              py={{ base: '20px', md: '30px' }}
              px={{ base: '18px', md: '20px', xl: '40px' }}
              position="relative"
              width="100%"
            >
              <Text
                color="yellow.100"
                fontSize={{ base: '16px', md: '26px' }}
                pt="0"
                mt="0"
                fontFamily="FedraSansStd-book"
                fontWeight="400"
                lineHeight="1.2"
              >
                {card?.time}
              </Text>
              <Text
                color="white.50"
                fontSize={{ base: '18px', md: '26px' }}
                pt="10px"
                mt="0"
                fontFamily="FedraSansStd-A-medium"
                lineHeight="1.2"
              >
                {card?.title}
              </Text>
              {isLargerThan480 && (
                <>
                  <Box color="blue.200">
                    <StructuredTextParser
                      str={render(card?.description)}
                      fontFamily="FedraSansStd-book"
                    />
                  </Box>
                  {render(card?.highlightContent) &&
                    render(card?.highlightContent) !== '<p></p>' && (
                      <Box color="white.50">
                        <StructuredTextParser
                          str={render(card?.highlightContent)}
                          fontFamily="FedraSansStd-medium"
                          textAlign="center"
                          p="20px"
                          bgColor="#7073A1"
                          fontSize="16px"
                        />
                      </Box>
                    )}
                </>
              )}
            </Box>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default MsrEventScheduleCard;
