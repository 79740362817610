/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import { TestimonialCard } from '@msr/components/Card/TestimonialCard';

/* Services */
/* Styles */
// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';

/**
 * Renders the TestimonialsGroupV2 component.
 *
 * @param {object} testimonialsObj component.
 * @param testimonialsObj.sectionContent
 * @param {string} region - Region of page
 * @param {string} lang - Language of page
 * @param testimonialsObj.myRef
 * @param testimonialsObj.region
 * @param testimonialsObj.lang
 * @returns {ReactElement} TestimonialsGroupV2 component.
 */
const TestimonialsGroupV2 = ({ sectionContent, myRef, region, lang }) => {
  const settings = {
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    lazyLoad: true,
    infinite: true,
    nextArrow: <></>,
    prevArrow: <></>,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          m="0px 8px"
          bgColor="#c4c4c4"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  return (
    <Box
      px={{ base: '0', md: '50px' }}
      py="20px"
      maxW={{ base: '100%', xl: '1162px' }}
      m="0 auto"
      id="msr-testimonial-slider"
      textAlign="center"
    >
      <Slider {...settings}>
        {sectionContent?.testimonials?.map(post => {
          return post && <TestimonialCard post={post} />;
        })}
      </Slider>
      <style jsx global>
        {`
          .slick-dots li.slick-active p {
            background-color: #f5ac3e;
          }
          @media (max-width: 768px) {
            #msr-testimonial-slider .slick-list .slick-active {
              width: 315px !important;
            }
          }
          @media (max-width: 320px) {
            // #wta-container {
            //   padding: 60px 0;
            // }
            #msr-testimonial-slider .slick-list .slick-active {
              width: 300px !important;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default TestimonialsGroupV2;
