const LikesSvgIcon = ({ isActive }) => {
  return (
    <>
      <svg
        className="hoverIcon"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="22"
          cy="22"
          r="21"
          fill={isActive ? '#FFA735' : '#000'}
          fill-opacity={isActive ? '1' : '0.3'}
          stroke="#FFA735"
          stroke-width="2"
        />
        <path
          d="M18.75 29V20.75M15 22.25V27.5C15 28.3284 15.6716 29 16.5 29H26.5697C27.6803 29 28.6246 28.1898 28.7935 27.0921L29.6012 21.8421C29.8109 20.4792 28.7564 19.25 27.3774 19.25H24.75C24.3358 19.25 24 18.9142 24 18.5V15.8494C24 14.828 23.172 14 22.1507 14C21.907 14 21.6863 14.1435 21.5873 14.3661L18.948 20.3046C18.8276 20.5755 18.559 20.75 18.2626 20.75H16.5C15.6716 20.75 15 21.4216 15 22.25Z"
          stroke={isActive ? '#000' : '#FFA735'}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default LikesSvgIcon;
