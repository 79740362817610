const ShareSvgIcon = ({ isActive = false }) => {
  return (
    <>
      <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="19.168" cy="19" r="18" fill={isActive ? '#FFA735' : '#000'}
          fill-opacity={isActive ? '1' : '0.3'} stroke="#FFA735" stroke-width="1.75661"/>
<path d="M15.2843 20.0371L19.9677 22.7662M19.9609 15.2371L15.2843 17.9662M23.794 14.2017C23.794 15.3378 22.873 16.2588 21.7369 16.2588C20.6007 16.2588 19.6797 15.3378 19.6797 14.2017C19.6797 13.0655 20.6007 12.1445 21.7369 12.1445C22.873 12.1445 23.794 13.0655 23.794 14.2017ZM15.5655 19.0017C15.5655 20.1378 14.6444 21.0588 13.5083 21.0588C12.3722 21.0588 11.4512 20.1378 11.4512 19.0017C11.4512 17.8655 12.3722 16.9445 13.5083 16.9445C14.6444 16.9445 15.5655 17.8655 15.5655 19.0017ZM23.794 23.8017C23.794 24.9378 22.873 25.8588 21.7369 25.8588C20.6007 25.8588 19.6797 24.9378 19.6797 23.8017C19.6797 22.6655 20.6007 21.7445 21.7369 21.7445C22.873 21.7445 23.794 22.6655 23.794 23.8017Z" stroke={isActive ? '#000' : '#FFA735'} stroke-width="1.75661" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </>
  );
};

export default ShareSvgIcon;
