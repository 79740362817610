/* Built In Imports */
import { Box } from '@chakra-ui/react';

/* External Imports */
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the MsrIntroSadhana component.
 *
 * @param sectionContent.sectionContent
 * @param {Array} sectionContent - Content from api.
 * @param {string} lang - Current User Language.
 * @param sectionContent.lang
 * @returns {ReactElement} MsrIntroSadhana Component.
 */
const MsrIntroSadhana = ({ sectionContent, lang }) => {
  return (
    <Box className="msrIntroSadhana">
      <StructuredTextParser
        str={render(sectionContent.body)}
        region={''}
        lang={lang}
        textAlign={sectionContent.style}
        maxW="100%"
        theme="light"
        m="0"
      />
      <style>
        {`
        .msrIntroSadhana {
          max-width: 516px;
          margin: 0 auto;
        }
        
        .msrIntroSadhana p {
          margin: 5px;

        }
        .msrIntroSadhana ul {
          max-width: 396px;
          margin: 15px auto;
        }
        .msrIntroSadhana li {
          text-align: left;
        }
        `}
      </style>
    </Box>      
  );
};

export default MsrIntroSadhana;
