/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import ShivaBlockCard from '@msr/components/Card/ShivaBlockCard';

/* Services */

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const MsrShivaPopularContent = ({ sectionContent, lang }) => {
  return (
    <Box mx="auto" justifyContent="center" mt="20px">
      {/* <Box maxW={{ md: '750px', lg: '1140px' }} mx="auto">
        <Heading
          fontSize={{ base: '26px', md: '40px' }}
          as="h2"
          px={{ base: '16px', md: '0' }}
          color="#FFF7E9"
          mb="23px"
          mt="60px"
        >
          Popular Shiva Stories
        </Heading>
      </Box> */}
      <Box
        gridGap={{ base: '20px', md: '15px' }}
        display={{ base: 'grid', md: 'flex' }}
        flexFlow="wrap"
        mx={{ base: '4', md: 'auto' }}
        justifyContent="center"
      >
        {sectionContent.shivaPosts &&
          sectionContent.shivaPosts?.map(post => {
            return (
              <Box maxW="370px" width="100%" bg="#252964">
                <ShivaBlockCard content={post} lang={lang} />{' '}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};
export default MsrShivaPopularContent;
