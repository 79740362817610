/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/layout';

/* Internal Imports */
/* Components */
import SliderCommon from '@msr/components/Sliders/SliderCommon';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Render Slider Gallery Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data from api
 * @param sectionContent.isLeftMenu
 * @param sectionContent.showArrows
 * @returns {ReactElement} - Slider Gallery Component
 */
const SliderGallery = ({ sectionContent, isLeftMenu, showArrows }) => {
  const bgColors = {
    grey: 'gray.300',
  };

  return (
    <Box
      bgColor={bgColors[sectionContent?.style]}
      w="100%"
      p="10px 0"
      textAlign="center"
    >
      <Box w="100%">
        <SliderCommon
          className={sectionContent.fieldId}
          sectionContent={sectionContent}
          isLeftMenu={isLeftMenu}
          showArrows={showArrows}
        />
      </Box>
    </Box>
  );
};

export default SliderGallery;
