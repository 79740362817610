/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

/* External Imports */
import { Box, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import AccordionSection from '@components/Accordions/AccordionSection';
import Faq from '@components/Accordions/FaqQuestionier';
import CardInfo2 from '@components/Card/CardInfo2';
import CardInfo3 from '@components/Card/CardInfo3';
import CardInfo4 from '@components/Card/CardInfo4';
import SingleImage from '@components/Card/SingleImage';
import SectionTitle from '@components/Headings/SectionTitle';
import TopKolam from '@components/Headings/TopKolam';
import CardInfo2Slider from '@components/Sliders/CardInfo2Slider';
import ImageGallery from '@components/Sliders/ImageGallery';
import JsonTable from '@components/Tables/JsonTable';
import FaqExpandedSection from '@components/UI/FaqExpandedSection';
import HtmlBlock from '@components/UI/HtmlBlock';
import ImportantMessage from '@components/UI/ImportantMessage';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import SingleLineText from '@components/UI/SingleLineText';
import MidContainer from '@Layout/MidContainer';
import FaqSectionV2 from '@msr/components/Accordions/FaqSectionV2';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import CardMsr3ColumnArtistSliderGroup from '@msr/components/Card/CardMsr3ColumnArtistSliderGroup';
import MsrArtistVideoCardDetail from '@msr/components/Card/MsrArtistVideoCardDetail';
import MsrArtistVideoCardGroup from '@msr/components/Card/MsrArtistVideoCardGroup';
import MsrDownloadsGroup from '@msr/components/Card/MsrDownloadsGroup';
import MsrEventScheduleCardGroup from '@msr/components/Card/MsrEventScheduleCardGroup';
import MsrEventScheduleImageCardGroup from '@msr/components/Card/MsrEventScheduleImageCardGroup';
import MsrPerformingArtistsCardZigzagGroup from '@msr/components/Card/MsrPerformingArtistsCardZigzagGroup';
import MsrShivaPopularContent from '@msr/components/Card/MsrShivaPopularContent';
import ShivaTopic from '@msr/components/Card/ShivaTopic';
import ShivaTopicContent from '@msr/components/Card/ShivaTopicContent';
import SoiMusicHorizontalCard from '@msr/components/Card/SoiMusicHorizontalCard';
import EventScheduleDesc from '@msr/components/FieldIds/EventScheduleDesc';
import ImpDateAnnounce from '@msr/components/FieldIds/ImpDateAnnounce';
import LiveStreamDate from '@msr/components/FieldIds/LiveStreamDate';
import LiveStreamList from '@msr/components/FieldIds/LiveStreamList';
import LiveStreamSubTitle from '@msr/components/FieldIds/LiveStreamSubTitle';
import LiveStreamTitle from '@msr/components/FieldIds/LiveStreamTitle';
import LiveStreamWatchLive from '@msr/components/FieldIds/LiveStreamWatchLive';
import MsrIntroSadhana from '@msr/components/FieldIds/MsrIntroSadhana';
import PartnerTitle from '@msr/components/FieldIds/PartnerTitle';
import SadhanaNote from '@msr/components/FieldIds/SadhanaNote';
import TvChannelsSubtitle from '@msr/components/FieldIds/TvChannelsSubtitle';
import MsrTopBanner from '@msr/components/HeaderBanner/MsrTopBanner';
import TopBannerVideo from '@msr/components/HeaderBanner/TopBannerVideo';
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';
import ImageWithTextSliderGroup from '@msr/components/Sliders/ImageWithTextSliderGroup';
import SliderGallery from '@msr/components/Sliders/SliderGallery';
import TestimonialsGroupV2 from '@msr/components/Sliders/TestimonialsGroupV2';
import JsonTableUI from '@msr/components/Tables/JsonTableUI';
import StickyTabGroup from '@msr/components/Tabs/StickyTabGroup';
import ImportantMessageFlexible from '@msr/components/UI/ImportantMessageFlexible';
import Quote from '@msr/components/UI/Quote';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the Section UI Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Content from API
 * @param {string} language - Language of page
 * @param {string} region - Region of Page
 * @param {boolean} isLeftMenu - Left Navbar
 * @param {string} pType
 * @param {string} pageName
 * @param sectionContent.region
 * @param sectionContent.language
 * @param sectionContent.section
 * @param sectionContent.isLeftMenu
 * @param sectionContent.pType
 * @param sectionContent.pageName
 * @param sectionContent.csrData
 * @param sectionContent.pageData
 * @returns {ReactElement} UpcomingDates component
 */
const SectionUI = ({
  sectionContent,
  region,
  language,
  section,
  isLeftMenu,
  pType,
  pageName,
  csrData,
  pageData,
}) => {
  const router = useRouter();
  const ignoreComponents = [
    'msr_section_title',
    'msr_top_banner',
    'top_banner_video',
    'structured_text',
  ];

  return (
    <Box
      w="100%"
      display="flex"
      flexDirection="column"
      pb={
        ignoreComponents.indexOf(sectionContent?._modelApiKey) < 0
          ? '15px'
          : '0'
      }
    >
      {RenderComponents({
        sectionContent,
        region,
        language,
        section,
        isLeftMenu,
        pType,
        pageName,
        csrData,
        router,
        pageData,
      })}
    </Box>
  );
};

const RenderComponents = ({
  sectionContent,
  region,
  language,
  section,
  isLeftMenu,
  pType,
  pageName,
  csrData,
  router,
  pageData,
}) => {
  // console.log('_modelApiKey', sectionContent._modelApiKey,  sectionContent);
  switch (sectionContent._modelApiKey) {
    case 'msr_top_banner':
      // const TopBannerSimple = await import('@components/HeaderBanner/TopBannerSimple');
      return <MsrTopBanner sectionContent={sectionContent} />;
    case 'top_banner_video':
      // const TopBannerSimple = await import('@components/HeaderBanner/TopBannerSimple');
      return <TopBannerVideo sectionContent={sectionContent} />;
    case 'top_kolam':
      // const TopKolam = dynamic(() => import('@components/Headings/TopKolam'));
      return <TopKolam sectionContent={sectionContent} />;
    case 'structured_text':
      if (
        sectionContent.fieldId === 'live-stream-list-in' ||
        sectionContent.fieldId === 'livestream-list-overseas'
      ) {
        return (
          <LiveStreamList sectionContent={sectionContent} lang={language} />
        );
      } else if (sectionContent.fieldId === 'partner-title') {
        return <PartnerTitle sectionContent={sectionContent} lang={language} />;
      } else if (sectionContent.fieldId === 'eventschdule-desc') {
        return (
          <EventScheduleDesc sectionContent={sectionContent} lang={language} />
        );
      } else if (sectionContent.fieldId === 'msr-intro-sadhana') {
        return (
          <MsrIntroSadhana sectionContent={sectionContent} lang={language} />
        );
      }

      // console.log('structured_text', sectionContent._modelApiKey, sectionContent);

      return (
        <MidContainer w="586px">
          <Box
            sx={{
              h2: {
                fontSize: '26px',
              },
              h3: {
                fontSize: '18px',
              },
            }}
            px={{ base: '15px', md: '0' }}
            py={{ base: '0', md: '0' }}
          >
            <StructuredTextParser
              str={render(sectionContent.body)}
              region={region}
              lang={language}
              textAlign={sectionContent.style}
              theme={
                pageData?.pageConfig &&
                pageData.pageConfig[0]?.value?.msr_theme === 'light'
                  ? 'dark'
                  : 'light'
              }
            />
          </Box>
        </MidContainer>
      );
    case 'section_title':
      // const SectionTitle = dynamic(() => import('@components/Headings/SectionTitle'));
      return <SectionTitle titleObj={sectionContent} />;
    case 'msr_section_title':
      return <MsrSectionTitle titleObj={sectionContent} pageName={pageName} />;
    case 'sticky_tab_group':
      return (
        <StickyTabGroup
          section={section}
          sectionContent={sectionContent}
          lang={language}
        />
      );
    case 'msr_shiva_popular_content':
      return (
        <MsrShivaPopularContent
          sectionContent={sectionContent}
          lang={language}
        />
      );
    case 'msr_shiva_section_facade_placeholder':
      if (sectionContent.contentType === 'mahashivratri_shiva_topic') {
        return (
          <ShivaTopic topics={sectionContent?.shiva_topic} lang={language} />
        );
      } else if (sectionContent.contentType === 'mahashivratri_parent') {
        return (
          <ShivaTopicContent
            shivaPosts={sectionContent?.shiva_content?.posts}
            lang={language}
          />
        );
      }
      break;
    case 'msr_downloads_group':
      return (
        <MsrDownloadsGroup
          sectionContent={sectionContent}
          lang={language}
          pType={pType}
        />
      );
    case 'card_info2_column_group':
      // const CardInfo2 = dynamic(() => import('@components/Card/CardInfo2'));
      return (
        <MidContainer w={'768'}>
          <Box display="flex" marginTop="50px">
            <CardInfo2
              sectionContent={sectionContent}
              region={region}
              lang={language}
            />
          </Box>
        </MidContainer>
      );
    case 'card_info4_column_group':
      // const CardInfo4 = dynamic(() => import('@components/Card/CardInfo4'));
      return (
        <CardInfo4
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'card_small_info3_column_group':
    case 'card_info3_column_group':
      // const CardInfo3 = dynamic(() => import('@components/Card/CardInfo3'));
      return (
        <CardInfo3
          sectionContent={sectionContent}
          region={region}
          lang={language}
          section={section}
          isLeftMenu={isLeftMenu}
        />
      );
    case 'simple_button':
      return (
        <RenderSimpleButton
          sectionContent={sectionContent}
          section={section}
          region={region}
          lang={language}
        />
      );
    case 'msr_event_schedule_card_group':
      return (
        <MsrEventScheduleCardGroup
          sectionContent={sectionContent}
          section={section}
          region={region}
          lang={language}
        />
      );

    case 'gallery':
      return (
        <RenderGallery
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'testimonials_group_v2':
      // const Testimonials = dynamic(() => import('@components/Sliders/Testimonials'));
      return (
        <Box
          display="flex"
          border="2px"
          borderColor="transparent"
          placeContent="center"
        >
          <TestimonialsGroupV2
            sectionContent={sectionContent}
            region={region}
            lang={language}
          />
        </Box>
      );
    case 'image_with_text_slider_group':
      return (
        <ImageWithTextSliderGroup
          lang={language}
          sectionContent={sectionContent}
        />
      );
    case 'newsletter_subscription_form':
      const SubscribeForm = dynamic(() =>
        import('@msr/components/UI/Blogs/SubscribeForm')
      );
      return (
        <SubscribeForm language={language} sectionContent={sectionContent} />
      );
    case 'single_image':
      // const SingleImage = dynamic(() => import('@components/Card/SingleImage'));
      if (section?.sectionId === 'event-partners-section') {
        return (
          <Box p="0 25px">
            <SingleImage
              my="10px"
              sectionContent={sectionContent}
              region={region}
              lang={language}
            />
          </Box>
        );
      } else if (section?.sectionId === 'sadhana-guideline-section') {
        return (
          <MidContainer>
            <Box
              textAlign="center"
              position="relative"
              mx={'auto'}
              my={10}
              // maxW={{ base: 302, sm: 330, lg: 470 }}
            >
              <Image
                h={'auto'}
                alt={sectionContent.image?.alt}
                title={sectionContent.image?.title}
                textAlign="center"
                src={sectionContent.image?.url}
                w={'auto'}
                maxWidth={'100%'}
                mx={{ sm: '0', lg: '11.5px' }}
              />
            </Box>
          </MidContainer>
        );
      } else if (section?.sectionId === 'athome-content-section') {
        return (
          <MidContainer>
            <Box
              textAlign="center"
              position="relative"
              mx={'auto'}
              mt={{ base: '40px', md: '60px' }}
              px={{ base: '16px', md: '0' }}
            >
              <LazyLoadImageComponent
                h={'auto'}
                alt={sectionContent.image?.alt}
                title={sectionContent.image?.title}
                textAlign="center"
                src={sectionContent.image?.url}
                w={'auto'}
                maxWidth={'100%'}
              />
            </Box>
          </MidContainer>
        );
      } else {
        return (
          <SingleImage
            sectionContent={sectionContent}
            region={region}
            lang={language}
          />
        );
      }
    case 'ad_image_banner':
      const ImageBannerAd = dynamic(() =>
        import('@components/Card/ImageBannerAd')
      );
      return (
        <ImageBannerAd
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'quote':
      // const Quote = dynamic(() => import('@components/UI/Quote'));
      return <Quote sectionContent={sectionContent} />;
    case 'media_embed':
      const MediaEmbed = dynamic(() =>
        import('@msr/components/Embeds/MediaEmbed')
      );
      return <MediaEmbed sectionContent={sectionContent} lang={language} />;
    case 'share_widget':
      const SocialLinks = dynamic(() => import('@components/UI/SocialLinks'));
      return <SocialLinks url={router.asPath} />;
    case 'google_map':
      const GoogleMap = dynamic(() => import('@components/Embeds/GoogleMap'));
      return <GoogleMap sectionContent={sectionContent} />;
    case 'important_message':
      // const ImportantMessage = dynamic(() => import('@components/UI/ImportantMessage'));
      return (
        <ImportantMessage
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'important_message_flexible':
      // console.log('important_message_flexible', sectionContent);
      return (
        <MidContainer w="836">
          <ImportantMessageFlexible
            sectionContent={sectionContent}
            region={region}
            lang={language}
          />
        </MidContainer>
      );
    case 'card_info2_column_slider_group':
      // const CardInfo2Slider = dynamic(() => import('@components/Sliders/CardInfo2Slider'));
      return (
        <CardInfo2Slider
          sectionContent={sectionContent}
          region={region}
          language={language}
        />
      );
    case 'json_table':
      return (
        <JsonTable
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'json_table_ui':
      return (
        <RenderJsonTableUI
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );

    case 'card_msr3_column_artist_slider_group':
      return (
        <CardMsr3ColumnArtistSliderGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );

    case 'msr_event_schedule_image_card_group':
      return (
        <MsrEventScheduleImageCardGroup
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'single_line_text':
      // const SingleLineText = dynamic(() => import('@components/UI/SingleLineText'));
      switch (sectionContent.fieldId) {
        case 'tv-channels-subtitle':
          return <TvChannelsSubtitle sectionContent={sectionContent} />;
        case 'msr-sadhana-note':
          return <SadhanaNote sectionContent={sectionContent} />;
        case 'live-stream-title':
          return <LiveStreamTitle sectionContent={sectionContent} />;
        case 'live-stream-date':
          return <LiveStreamDate sectionContent={sectionContent} />;
        case 'live-stream-subtitle':
          return <LiveStreamSubTitle sectionContent={sectionContent} />;
        case 'live-stream-watchlive':
          return <LiveStreamWatchLive sectionContent={sectionContent} />;
        case 'imp-date-announce':
          return <ImpDateAnnounce sectionContent={sectionContent} />;
        default:
          return <SingleLineText sectionContent={sectionContent} />;
      }
    case 'generic_iframe':
      const GenericFrame = dynamic(() =>
        import('@components/Embeds/GenericFrame')
      );
      return <GenericFrame sectionContent={sectionContent} section={section} />;
    case 'faq':
      // const Faq = dynamic(() => import('@components/Accordions/FaqQuestionier'));
      return <Faq sectionContent={sectionContent} />;
    case 'faq_section_v2':
      // const FaqSectionV2 = dynamic(() => import('@components/Accordions/FaqSectionV2'));
      return (
        <MidContainer w="750px">
          <FaqSectionV2
            faqSection={sectionContent}
            region={region}
            lang={language}
          />
        </MidContainer>
      );
    case 'faq_expanded_section':
      // const FaqExpandedSection = dynamic(() => import('@components/UI/FaqExpandedSection'));
      return (
        <FaqExpandedSection
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'accordion_section':
      // const AccordionSection = dynamic(() => import('@components/Accordions/AccordionSection'));
      return (
        <AccordionSection
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      );
    case 'social_media_icon':
      const SocialMediaIcon = dynamic(() =>
        import('@components/UI/SocialMediaIcon')
      );
      return <SocialMediaIcon sectionContent={sectionContent} />;
    case 'html_block':
      return <HtmlBlock sectionContent={sectionContent} />;
    case 'soi_music_horizontal_card':
      return <SoiMusicHorizontalCard sectionContent={sectionContent} />;
    case 'msr_artist_video_card_detail':
      return <MsrArtistVideoCardDetail sectionContent={sectionContent} />;
    case 'msr_artist_video_card_group':
      return <MsrArtistVideoCardGroup sectionContent={sectionContent} />;
    case 'msr_performing_artists_card_zigzag_group':
      return (
        <MsrPerformingArtistsCardZigzagGroup sectionContent={sectionContent} />
      );
  }
};

const RenderJsonTableUI = ({ sectionContent, region, language }) => {
  if (sectionContent?.fieldId === 'youtube-video-3column') {
    const JsonTableUIYouTubeVideo3Column = dynamic(() =>
      import('@components/Tables/JsonTableUIYouTubeVideo3Column')
    );
    return (
      <JsonTableUIYouTubeVideo3Column
        sectionContent={sectionContent}
        region={region}
        lang={language}
      />
    );
  } else {
    // const JsonTableUI = dynamic(() => import('@components/Tables/JsonTableUI'));
    return (
      <Box my={10}>
        <JsonTableUI
          sectionContent={sectionContent}
          region={region}
          lang={language}
        />
      </Box>
    );
  }
};

const RenderSimpleButton = ({ sectionContent, section, region, lang }) => {
  // const SimpleButton = dynamic(() => import('@components/Buttons/SimpleButton'));
  return <SimpleButton style={sectionContent} lang={lang} />;
};

const RenderGallery = ({ sectionContent, region, lang }) => {
  if (sectionContent.galleryType === 'without_hero_image') {
    // const SliderGallery = dynamic(() => import('@components/Sliders/SliderGallery'));
    return <SliderGallery sectionContent={sectionContent} showArrows={false} />;
  } else if (sectionContent.galleryType === 'with_hero_image') {
    // const ImageGallery = dynamic(() => import('@components/Sliders/ImageGallery'));
    return <ImageGallery sectionContent={sectionContent} />;
  } else {
    return (
      <Box display="flex" marginTop="80px">
        <Image loading="lazy" Gallery sectionContent={sectionContent} />
      </Box>
    );
  }
};

export default SectionUI;
