/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Loader from '@components/Utility/Loader';
import ShivaBlockCard from '@msr/components/Card/ShivaBlockCard';

/* Services */
import { fetchShivaBlogs } from 'services/msrService';

/**
 * @param {Object} shivaPosts
 * @returns
 */
const ShivaTopicContent = ({ shivaPosts, topic, lang, page }) => {
  const [posts, setPosts] = useState(shivaPosts?.cards);
  const [isLoader, setIsLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(shivaPosts?.totalCount);
  const [hasLoadMore, setHasLoadMore] = useState(true);
  const router = useRouter();

  const loadMorePosts = async () => {
    // consoleLog('morePosts', posts);
    setIsLoader(true);
    let morePosts = await fetchShivaBlogs(topic,lang, '', posts.length);
    // consoleLog('morePosts loaded', morePosts);
    if (morePosts?.posts?.cards?.length >= 0) {
      setPosts(prev => [...prev, ...morePosts?.posts.cards]);
      setTotalCount(morePosts?.posts?.totalCount);
    }
    // consoleLog('morePosts loaded', posts.length, totalCount, posts.length < totalCount);
    setHasLoadMore(posts.length + 12 < totalCount);
    setIsLoader(false);
  };

  useEffect(() => {
    setPosts(shivaPosts?.cards);
    // setTotalCount(shivaPosts?.cards?.length);
  }, [shivaPosts?.cards]);

  useEffect(
    () => () => {
      setPosts([]);
      setTotalCount(0);
    },
    [router.asPath]
  );

  return (
    <Box>
      {/* {topic && (
        <Box
          flexFlow="wrap"
          mx="auto"
          maxW={{ base: '767px', xl: '1140px' }}
          justifyContent="center"
        >
          <Box
            fontSize="40px"
x               textAlign="left"
            fontFamily="FedraSerifAStdBook"
            pt={{ base: '0', md: '60px' }}
            // mb="20px"
            as="h2"
            fontWeight="600"  
            color="#FFF7E9"
            textTransform="capitalize"
          >
            <Image
              src="/assets/images/tag.png"
              mr="17px"
            />
            {topic}
          </Box>
        </Box>
      )} */}
      <Box
        gridGap={{ base: '20px', md: '15px' }}
        gridRowGap={{ base: '20px', md: '40px' }}
        display={{ base: 'grid', md: 'flex' }}
        flexFlow="wrap"
        mx={{ base: '4', md: 'auto' }}
        mt={{ base: '60px', md: '80px' }}
        mb={{ base: '30px', md: '50px' }}
        maxW={{ base: '767px', xl: '1140px' }}
        justifyContent="center"
      >
        {posts?.map((post, i) => {
          return (
            <Box maxW="370px" width="100%" bg="#252964">
              <ShivaBlockCard content={post} lang={lang} page={page} />{' '}
            </Box>
          );
        })}
        {posts?.length > 0 && totalCount > 12 && hasLoadMore && (
          <Box
            pos="relative"
            d="flex"
            w="100%"
            m="25px auto 55px auto"
            display="block"
            alignItems={'center'}
            justifyContent="center"
          >
            <Box w="100%" textAlign="center" display={'block'}>
              <Link
                variant={'outline'}
                width="164px"
                p="16px 20px"
                fontWeight="500"
                borderRadius="5px"
                color="#FFA735"
                fontSize="16px"
                fontFamily="FedraSansStd-medium"
                textAlign="center"
                m="0 auto"
                mb="5px"
                background="#03062C"
                border="1px solid #FFA735"
                _hover={{
                  textDecoration: 'none',
                  color: '#DC8513',
                  border: '1px solid #DC8513',
                }}
                onClick={loadMorePosts}
                title="Load More"
                rel="next"
              >
                Load more ({posts?.length}/{totalCount})
              </Link>
            </Box>
            {isLoader && posts?.length < totalCount && (
              <Box w="100%" textAlign="center" display="block">
                <Loader pos="relative" />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShivaTopicContent;
