/* Built In Imports */

/* External Imports */
import { Box, Heading, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import Slider from 'react-slick';

/* Internal Imports */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
/* Services */

/**
 * Renders the Card Info 3 Card component
 * @param {Object} sectionContent - Data for section
 * @returns {ReactElement} CardGrid Card component
 */
const CardMsr3ColumnArtistSliderGroup = sectionContent => {
  const objArrows = {
    pos: 'absolute',
    top: '40%',
    zIndex: '10',
    cursor: 'pointer',
    bgSize: '10px',
    w: '46px',
    h: '46px',
    boxShadow: '0 0 7px 0 rgb(0 0 0 / 13%)',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    color: 'transparent',
    bgColor: '#fff',
    borderRadius: '50%',
  };

  const NextArrow = props => {
    const { onClick } = props;

    return (
      <Box
        {...objArrows}
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right={{ base: '-10px', md: '-15px' }}
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      ></Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        {...objArrows}
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left={{ base: '-10px', md: '-25px' }}
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      />
    );
  };

  const settings = {
    dots: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 11000,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          m={{ base: '-15px 8px', md: '0px 8px' }}
          bgColor="#c4c4c4"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  return (
    <Box mx="auto" w="full">
      <Box
        px={{ base: '10px', md: '50px' }}
        py="20px"
        maxW={{ base: '100%', lg: '1245px' }}
        w="full"
        mx="auto"
        className="artist-performers"
      >
        <Slider {...settings} className="slideW">
          {sectionContent &&
            sectionContent?.sectionContent?.cards?.map(cardDetail => {
              return (
                <Box
                  maxW="370px"
                  height={{ base: '275px', md: '480px' }}
                  w="full"
                  pos="relative"
                >
                  <Box textAlign="center">
                    <Image
                      src={cardDetail?.thumbnail?.url}
                      width="370px"
                      height={{ base: '220px', md: '370px' }}
                      objectFit="cover"
                    />

                    <Box
                      bg="#252964"
                      width="80%"
                      py="15px"
                      px="20px"
                      textAlign="center"
                      pos="absolute"
                      transform="translate(13%, -50%)"
                      h="117px"
                    >
                      <Heading
                        as="h3"
                        color="#FFF7E9"
                        fontFamily="FedraSansStd-A-medium"
                        fontWeight="500"
                        fontSize="26px"
                        mb="3px"
                        className="line1ellipsis"
                        title={cardDetail?.title}
                      >
                        {cardDetail?.title}
                      </Heading>
                      <Text
                        fontSize="16px"
                        color="#8F92C1"
                        fontFamily="FedraSansStd-book"
                      >
                        <StructuredTextParser
                          str={render(cardDetail?.description)}
                          textAlign="center-align"
                          fontFamily="FedraSansStd-book"
                          mt="0"
                          className="line2ellipsis"
                        />
                      </Text>
                    </Box>
                  </Box>
                </Box>
              );
            })}
        </Slider>
      </Box>
      <style>
        {`
          .slick-dots li.slick-active p {
            background-color: #f5ac3e;
          }
          @media (min-width:1024px) and (max-width:1140px) {
              .slideW {
                width: 1140px
              }
          }
          @media (max-width:767px) {
            .artist-performers .slick-list{
              margin-bottom: 50px !important;
            }
            .artist-performers .slick-dots{
              bottom: -50px;
            }
            .slideW {
              width: 100%;
            }
            .artist-performers .slick-dots li{
              width: 8px;
              height: 8px;
              margin: 0 0 0 8px;
            }
            .artist-performers .slick-dots li p{
              width: 8px;
              height: 8px;
              margin: 0;
            }
          }
          @media (max-width: 668px) {
            .slideW .slick-list .slick-active {
                    width: 296px !important;
                    margin:0 20px;
                    height:275px;
                  }
                }
        `}
      </style>
    </Box>
  );
};

export default CardMsr3ColumnArtistSliderGroup;
