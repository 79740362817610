/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@msr/components/Utility/utils';

/* Services */

/**
 *
 * @param {Object} topics
 * @returns
 */
const ShivaTopic = ({ topics, lang, page, theme = 'dark' }) => {
  return topics?.length > 0 && (
    <Box
      display="flex"
      flexFlow="wrap"
      mt={{ base: '15px', md: '30px' }}
      mx="auto"
      maxW={{ md: '750px', lg: '1140px' }}
      fontFamily="FedraSansStd-book"
      flexWrap="wrap"
      justifyContent="left"
    >
      <Box
        mx={{ base: '16px', md: '0' }}
        gridGap={{ base: '14px', md: '18px' }}
        display="flex"
        flexWrap="wrap"
        justifyContent="left"
      >
        {topics?.map(topic => {
          return topic?.title &&
          topic && (
            <NextLink
              href={refineUrlDomain(
                topic?.urlAlias || topic?.url,
                '',
                lang,
                page
              )}
              passHref
              legacyBehavior>
              <Link
                color={theme === 'light' ? '#01031D' : '#8F92C1'}
                background={
                  theme === 'light' ? '#FFF7E9' : 'rgba(37, 41, 100, 0.4)'
                }
                border={
                  theme === 'light'
                    ? '1px solid #01031D'
                    : '1px solid #8F92C1'
                }
                borderRadius="4px"
                fontSize="12px"
                p={{ base: '6px 8px', md: '10px 30px' }}
                _hover={{
                  textDecorationStyle: 'none',
                  background: theme === 'light' ? '#EFD7AC' : '#252964',
                }}
              >
                {topic?.title}
              </Link>
            </NextLink>
          );
        })}
      </Box>
    </Box>
  );
};

export default ShivaTopic;
