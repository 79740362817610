/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@msr/Utility/StructuredTextParser';
import { downloadUrl } from '@msr/components/Utility/utils';
import DownloadSvgIcon from '../Buttons/DownloadSvgIcon';

/**
 *
 * @param {Object} cardData
 * @returns
 */
const EbookCard = ({ cardData }) => {
  const dataLayerPush = eType => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'msr_engagements',
      product_category: 'ebooks', //e.g. wallpaper or wishes or songs or videos or ebooks
      engagement_type: eType, //e.g. download or share or like
      title: cardData?.title
    });
  };

  const download = async (url) => {
    dataLayerPush('download');
    downloadUrl(url, `ebook-${new Date().getMilliseconds()}`);
  };

  return (
    <Box
      bgColor="#252964"
      display={{ base: '', md: 'flex' }}
      className="shivaEbooksSection"
    >
      <Box display="flex" alignItems="center" justifyItems="center">
        <Box
          backgroundImage={cardData?.thumbnailImage?.url}
          h={{ base: '146px', md: '100%', lg: '270px' }}
          flexGrow="0"
          flexShrink="0"
          flexBasis={{ base: '114px', md: '212px' }}
          backgroundSize={{ base: 'contain', md: 'cover' }}
          bgRepeat="no-repeat"
        />
        <Text
          fontSize={{ base: '18px', md: '22px', lg: '26px' }}
          color="#FFF7E9"
          fontWeight="600"
          p="15px 15px 15px 26px"
          fontFamily="FedraSerifAStdBook"
          display={{ base: 'flex', md: 'none' }}
        >
          {cardData?.title}
        </Text>
      </Box>
      <Box p={{ base: '20px', md: '40px' }} width="100%">
        <Flex flexDirection="column" pl={{ base: '0', md: '210px' }}>
          <Flex mt="auto" w="full" justifyContent="space-between">
            <Text
              fontSize={{ base: '18px', md: '22px', lg: '26px' }}
              color="#FFF7E9"
              fontWeight="600"
              fontFamily="FedraSerifAStdBook"
              display={{ base: 'none', md: 'flex' }}
            >
              {cardData?.title}
            </Text>

            <Box
              fontSize="16px"
              width="82px"
              display={{ base: 'none', md: 'flex' }}
              justifyContent="end"
            >
              <Link 
                onClick={e => { download(cardData?.downloadLink) }}
              >
                <DownloadSvgIcon />
              </Link>
            </Box>
          </Flex>
          <Box pb={{ base: '20px', md: 0 }}>
            <StructuredTextParser
              str={render(cardData.description)}
              theme="light"
            />
          </Box>
        </Flex>
        <Flex
          mt="auto"
          justifyContent="end"
          alignItems="center"
          display={{ md: 'none' }}
        >
          <Box fontSize="16px" display="flex" justifyContent="end">
            <Link
              // href={cardData?.downloadLink}
              // isExternal
              // onClick={e => dataLayerPush('download')}
              onClick={e => { download(cardData?.downloadLink) }}
            >
              <DownloadSvgIcon />
            </Link>
          </Box>
        </Flex>
      </Box>
      <style>
        {` 
        .shivaEbooksSection  .structured-content-light {  
          max-width:100%;
        }
        .shivaEbooksSection  .structured-content-light p {
          margin: 0;
        }
        `}{' '}
      </style>
    </Box>
  );
};

export default EbookCard;
