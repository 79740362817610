/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the LiveStreamTitle component.
 *
 * @param sectionContent.sectionContent
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} ImpDateAnnounce Component.
 */
const ImpDateAnnounce = ({ sectionContent }) => {
  return (
    <MidContainer w="700px">
      <Box
        pos="relative"
        fontSize={{ base: '18px' }}
        color="#FFF7E9"
        p="17px 20px "
        fontFamily="FedraSansStd-book"
        textAlign={{ base: 'center' }}
        mt="70px"
        px={{ base: '15px', md: 0 }}
        bgColor="#424786"
        borderRadius="5px"
        maxW="570px"
        mx={{ base: '15px', lg: 'auto' }}
      >
        <Box pos="absolute" top="-13px" left="25px">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 7.1C1.5 5.13981 1.5 4.15972 1.88148 3.41103C2.21703 2.75246 2.75246 2.21703 3.41103 1.88148C4.15972 1.5 5.13981 1.5 7.1 1.5H16.9C18.8602 1.5 19.8402 1.5 20.589 1.88148C21.2476 2.21703 21.783 2.75246 22.1185 3.41103C22.5 4.15972 22.5 5.13981 22.5 7.1V13.75C22.5 15.3808 22.5 16.1961 22.2335 16.8394C21.8784 17.6969 21.1969 18.3784 20.3394 18.7335C19.6961 19 18.8808 19 17.25 19C16.68 19 16.3949 19 16.1306 19.0624C15.778 19.1456 15.4497 19.3099 15.1716 19.5419C14.963 19.716 14.7919 19.944 14.45 20.4L12.7467 22.6711C12.4934 23.0089 12.3667 23.1777 12.2114 23.2381C12.0755 23.291 11.9245 23.291 11.7886 23.2381C11.6333 23.1777 11.5066 23.0089 11.2533 22.6711L9.55 20.4C9.20799 19.944 9.03699 19.716 8.82842 19.5419C8.55031 19.3099 8.22197 19.1456 7.86943 19.0624C7.60501 19 7.32001 19 6.75 19C5.11921 19 4.3038 19 3.66061 18.7335C2.80302 18.3784 2.12165 17.6969 1.76642 16.8394C1.5 16.1961 1.5 15.3808 1.5 13.75V7.1Z"
              fill="#FFA735"
              stroke="#FFF7E9"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 14.3333V10.25M12 6.16666H12.0117"
              stroke="black"
              strokeWidth="2.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        {sectionContent?.text}
      </Box>
    </MidContainer>
  );
};

export default ImpDateAnnounce;
