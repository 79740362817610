/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the LiveStreamSubTitle component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} LiveStreamSubTitle Component.
 */

const LiveStreamSubTitle = ({ sectionContent }) => {
  return (
    <MidContainer w="586px">
      <Box
        as="h6"
        fontWeight="500"
        fontSize={{ base: '20px' }}
        color="#FFF7E9"
        pb={{ base: '30px', md: '20px' }}
        fontFamily="FedraSansStd-book"
        textAlign="center"
        // mt="30px"
        px={{ base: '10px', md: 0 }}
      >
        {sectionContent?.text}
      </Box>
    </MidContainer>
  );
};

export default LiveStreamSubTitle;
