/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import MediaEmbed from '@msr/components/Embeds/MediaEmbed';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the MsrArtistVideoCardGroup Card component
 * @param {Object} sectionContent - Data for section
 * @returns {ReactElement} MsrArtistVideoCardGroup Card component
 */
const MsrArtistVideoCardGroup = sectionContent => {
  return (
    <Box
      maxW="938px"
      mx={{ base: '16px', md: 'auto' }}
      pt={{ base: '20px', md: '80px' }}
    >
      {sectionContent &&
        sectionContent?.sectionContent?.cards?.map(cardData => {
          const embedObj = {
            embedUrl: cardData.cardLink,
            source: 'youtube',
          };
          return (
            <Box
              display="flex"
              flexDirection={{ base: 'column', md: 'row' }}
              p={{ base: '0 0 30px 0', md: '20px', lg: '30px' }}
              mb="30px"
              bgColor="blue.600"
            >
              {!cardData.cardLink && (
                <LazyLoadImageComponent
                  w={{ base: '100%', md: '335px' }}
                  h={{ base: 'auto', md: '220px' }}
                  src={cardData?.thumbnail?.url}
                  objectFit="cover"
                  objectPosition="top"
                  mb={{ base: '30px', md: 0 }}
                />
              )}
              {cardData.cardLink && (
                <Box
                  w={{ base: '100%', md: '335px' }}
                  h={{ base: 'auto', md: '220px' }}
                  mb={{ base: '30px', md: 0 }}
                >
                  <MediaEmbed sectionContent={embedObj} margin="0" pxBase="0" />
                </Box>
              )}
              <Box
                pl={{ base: '20px', md: '20px', lg: '40px' }}
                pr={{ base: '20px', md: '0' }}
                width={{ base: '100%', md: '68%' }}
              >
                <Flex flexDirection="column" justifyContent="center">
                  <Text
                    fontSize={{ base: '24px', md: '26px' }}
                    color="white.50"
                    display="flex"
                    fontWeight="600"
                    fontFamily="FedraSerifAStdBook"
                  >
                    {cardData?.title}
                  </Text>
                  <Text
                    display="flex"
                    color="#8F92C1"
                    fontFamily="FedraSerifAStdBook"
                  >
                    <StructuredTextParser
                      str={render(cardData.description)}
                      theme="light"
                      fontFamily="FedraSansStd-book"
                      fontSize="14px"
                    />
                  </Text>
                </Flex>
                <Flex
                  mt="auto"
                  justifyContent="end"
                  alignItems="center"
                  display={{ md: 'none' }}
                />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default MsrArtistVideoCardGroup;
