/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Link } from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import DownloadSvgIcon from '@msr/components/Buttons/DownloadSvgIcon';
import LikesSvgIcon from '@msr/components/Buttons/LikesSvgIcon';
import { downloadUrl, numFormatter } from '@msr/components/Utility/utils';

/* Services */
import { postLikes } from 'services/msrService';

/**
 * @param cardData.cardData
 * @param {object} cardData
 * @param cardData.lang
 * @returns
 */
const Wallpapers = ({ cardData, lang }) => {
  const envName = config.ENV_NAME === 'production' ? '' : config.ENV_NAME + '_';
  const likeCookieName = `${envName}msr_likes_id`;
  const [likeCount, setLikeCount] = useState(cardData?.likestotalcount);
  // const [activeBtn, setActiveBtn] = useState('none');
  const [likesCookie, setCookie, removeCookie] = useCookies([likeCookieName]);
  const [meLiked, setMeLiked] = useState(false);
  const likeCountLimit = 100;
  const isDesktop = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    if (likesCookie[likeCookieName]?.indexOf(cardData?.uuid) > -1) {
      setMeLiked(true);
    }
    if (meLiked && likeCount === 0) {
      setLikeCount(1);
    }
  }, [meLiked]);

  const handleClick = () => {
    postLikeFromAPI();
  };

  const postLikeFromAPI = async () => {
    const { contentType, uuid } = cardData;
    // consoleLog('postLikeFromAPI called', contentType, uuid);
    const response = await postLikes({
      uuid: uuid,
      action: meLiked ? 'remove' : 'add',
      contenttype: 'mahashivratri_shiva_section',
      language: lang || 'en',
    });
    const responseBody = await response;
    setLikeCount(responseBody?.likestotalcount);
    setMeLiked(!meLiked);
    // consoleLog('cookieData', likesCookie[likeCookieName], meLiked, likeCount, responseBody);
    let cookieData = likesCookie[likeCookieName];
    if (cookieData?.length && meLiked) {
      const index = cookieData.indexOf(uuid);

      if (index > -1) {
        // only splice array when item is found
        cookieData = cookieData.splice(index, 1);
        // consoleLog('index', likesCookie, index, cookieData);
      }
    } else {
      if (!cookieData) {
        cookieData = [];
      }
      cookieData.push(uuid);
    }

    // consoleLog('setCookie', likeCookieName, meLiked);
    // document.cookie = `${likeCookieName}=${cookieData} Path=/; domain==${config.COOKIE_DOMAIN} Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    setCookie(likeCookieName, cookieData, {
      path: '/',
      maxAge: 3600 * 24 * 365, // Expires after 1year
      sameSite: true,
      domain: config.COOKIE_DOMAIN,
      secure: true,
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'msr_engagements',
      product_category: 'wallpaper', //e.g. wallpaper or wishes or songs or videos or ebooks
      engagement_type: 'like', //e.g. download or share or like
      title: cardData?.title,
    });
  };

  const dataLayerPush = eType => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'msr_engagements',
      product_category: 'wallpaper', //e.g. wallpaper or wishes or songs or videos or ebooks
      engagement_type: eType, //e.g. download or share or like
      title: cardData?.title,
    });
  };

  // const downloadUrl1 = (url, e) => {
  //   dataLayerPush('download');
  //  consoleLog('url', url, e);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = url;;
  //   a.click();
  //   saveAs('image', url);
  // };

  const download = async url => {
    dataLayerPush('download');
    downloadUrl(url, `wallpaper-${new Date().getMilliseconds()}`);
  };

  return (
    <Box
      display="grid"
      flexDirection="column"
      gridColumnGap="16px"
      height="100%"
      gridRowStart="40px"
    >
      <Box
        // bgImage={{
        //   base: cardData?.wallpaperMobile?.url,
        //   md: cardData?.wallpaperDesktop?.url,
        // }}
        h={{ base: '560px', md: '200px', lg: '315px' }}
        width="100%"
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
      >
        {isDesktop && (
          <LazyLoadImageComponent
            src={cardData.wallpaperDesktop?.url}
            title={cardData.wallpaperDesktop?.title}
            alt={cardData.wallpaperDesktop?.alt}
            h={{ base: '560px', md: '200px', lg: '315px' }}
          />
        )}
        {!isDesktop && (
          <LazyLoadImageComponent
            src={cardData?.wallpaperMobile?.url}
            title={cardData?.wallpaperMobile?.title}
            alt={cardData?.wallpaperMobile?.alt}
            h={{ base: '560px', md: '200px', lg: '315px' }}
          />
        )}

        <Box
          background="linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 55.81%, #000000 100%)"
          position="absolute"
          zIndex="0"
          left="0"
          bottom="0"
          width="100%"
        >
          <Flex
            px={{ base: '20px', md: '30px' }}
            py="20px"
            justifyContent="space-between"
            alignItems="flex-end"
            height="130px"
          >
            <Box display="flex" alignItems="center">
              <Link mr="10px" onClick={handleClick} cursor="pointer" d="flex">
                <LikesSvgIcon isActive={meLiked} />
                {likeCount > likeCountLimit && (
                  <Box
                    as="span"
                    fontWeight="600"
                    color="#FFA735"
                    ml="5px"
                    alignSelf="center"
                    fontFamily="FedraSansStd-book"
                  >
                    {numFormatter(likeCount)}
                  </Box>
                )}
              </Link>
            </Box>

            <Box display={{ base: 'none', md: 'flex' }}>
              <Link
                onClick={e => {
                  download(cardData?.wallpaperDesktop?.url);
                }}
              >
                <DownloadSvgIcon />
              </Link>
            </Box>

            <Box display={{ base: 'flex', md: 'none' }}>
              <Link
                onClick={e => {
                  download(cardData?.wallpaperMobile?.url);
                }}
              >
                <DownloadSvgIcon />
              </Link>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Wallpapers;
