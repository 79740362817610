/* Built In Imports */

/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MediaEmbed from '@msr/components/Embeds/MediaEmbed';

/* Services */

/**
 * Renders the MsrArtistVideoCardDetail Card component
 * @param {Object} sectionContent - Data for section
 * @returns {ReactElement} MsrArtistVideoCardDetail Card component
 */

const MsrArtistVideoCardDetail = ({ sectionContent }) => {
  const embedObj = {
    embedUrl: sectionContent.videoUrl,
    source: 'youtube',
  };

  if (sectionContent.style === 'large') {
    return (
      <Box px={{ base: '16px', lg: '0' }}>
        <Box display="flex" flexDirection="row" mt="15px">
          <Box width="100%" bgColor="blue.600">
            <MediaEmbed
              sectionContent={embedObj}
              margin="0"
              px={{ base: '0', md: 0 }}
            />

            <Flex
              justifyContent="space-between"
              p="10px 5%"
              flexDir={{ base: 'column', md: 'row' }}
              alignItems={{ base: 'flex-start', md: 'center' }}
            >
              <Text
                fontSize={{ base: '18px', md: '26px' }}
                fontFamily="FedraSansStd-A-medium"
                color="#fff"
                fontWeight="500"
              >
                {sectionContent?.artistName}
              </Text>

              <Flex
                fontWeight="700"
                flexDirection="column"
                gridGap="0"
                alignItems="right"
                color="#FFA735"
                fontSize={{ base: '14px', md: '18px' }}
                fontFamily="FedraSansStd-book"
                textAlign={{ base: 'left', md: 'right' }}
              >
                Singing {sectionContent?.songName}
                <Box color="#fff" fontWeight="400">
                  {sectionContent?.event}
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        display={{ base: 'block', md: 'block' }}
        sx={{
          '.embedBox': { margin: '0', maxW: { base: '755px', md: '755px' } },
        }}
      >
        <MediaEmbed sectionContent={embedObj} margin="0" pxBase="0" />

        <Box p={{ base: '15px 20px', md: '15px 20px' }}>
          <Text
            as="p"
            fontSize={{ base: '16px', md: '18px' }}
            fontFamily="FedraSansStd-A-medium"
            color="#fff"
            fontWeight="500"
          >
            {sectionContent?.artistName}
          </Text>
          <Box display="flex" gridGap="10px" alignItems="center">
            <Box
              as="span"
              fontWeight="400"
              color="#FFA735"
              fontFamily="FedraSansStd-book"
              fontSize="14px"
            >
              {sectionContent?.songName}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default MsrArtistVideoCardDetail;
