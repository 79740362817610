/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import NextLink from 'next/link';
import { useCookies } from 'react-cookie';

/* Internal Imports */
/* Components */
import config from '@config';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';
import { downloadUrl, numFormatter } from '@msr/components/Utility/utils';
import DownloadSvgIcon from '../Buttons/DownloadSvgIcon';
import LikesSvgIcon from '../Buttons/LikesSvgIcon';
import MoreSvgIcon from '../Buttons/MoreSvgIcon';

/* Services */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { postLikes } from 'services/msrService';

/**
 *
 * @param root0
 * @param root0.cardData
 * @param root0.lang
 */
const SongsCard = ({ cardData, lang }) => {
  const [isAvailableOn, setIsAvailableOn] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handlerAvailableOn = () => {
    setIsAvailableOn(!isAvailableOn);
  };

  const envName = config.ENV_NAME === 'production' ? '' : config.ENV_NAME + '_';
  const likeCookieName = `${envName}msr_likes_id`;
  const [likeCount, setLikeCount] = useState(cardData?.likestotalcount);
  const [likesCookie, setCookie, removeCookie] = useCookies([likeCookieName]);
  const [meLiked, setMeLiked] = useState(false);
  const likeCountLimit = 100;

  // consoleLog('likesCount', likesCount, data);

  useEffect(() => {
    if (likesCookie[likeCookieName]?.indexOf(cardData?.uuid) > -1) {
      setMeLiked(true);
    }
    // consoleLog('meLiked', meLiked);
    if (meLiked && likeCount === 0) {
      setLikeCount(1);
    }
  }, [meLiked]);

  const handleClick = e => {
    e.preventDefault();
    postLikeFromAPI();
    setIsActive(!isActive);
  };

  const postLikeFromAPI = async () => {
    const { contentType, uuid } = cardData;
    // consoleLog('postLikeFromAPI called', contentType, uuid);
    const response = await postLikes({
      uuid: uuid,
      action: meLiked ? 'remove' : 'add',
      contenttype: 'mahashivratri_shiva_section',
      language: lang || 'en',
    });
    const responseBody = await response;
    setLikeCount(responseBody?.likestotalcount);
    setMeLiked(!meLiked);
    // consoleLog('cookieData', likesCookie[likeCookieName], meLiked, likeCount, responseBody);
    let cookieData = likesCookie[likeCookieName];
    if (cookieData?.length && meLiked) {
      const index = cookieData.indexOf(uuid);

      if (index > -1) {
        // only splice array when item is found
        cookieData = cookieData.splice(index, 1);
        // consoleLog('index', likesCookie, index, cookieData);
      }
    } else {
      if (!cookieData) {
        cookieData = [];
      }
      cookieData.push(uuid);
    }

    // consoleLog('setCookie', likeCookieName, meLiked);
    // document.cookie = `${likeCookieName}=${cookieData} Path=/; domain==${config.COOKIE_DOMAIN} Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    setCookie(likeCookieName, cookieData, {
      path: '/',
      maxAge: 3600 * 24 * 365, // Expires after 1year
      sameSite: true,
      domain: config.COOKIE_DOMAIN,
      secure: true,
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'msr_engagements',
      product_category: 'songs', //e.g. wallpaper or wishes or songs or videos or ebooks
      engagement_type: 'like', //e.g. download or share or like
      title: cardData?.title,
    });
  };

  const dataLayerPush = eType => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'msr_engagements',
      product_category: 'songs', //e.g. wallpaper or wishes or songs or videos or ebooks
      engagement_type: eType, //e.g. download or share or like
      title: cardData?.title,
    });
  };

  const download = url => {
    // saveAs('url', url);
    dataLayerPush('download');
    downloadUrl(url, `song-${new Date().getMilliseconds()}`);
  };

  return (
    <Box
      display="flex"
      p={{ base: '15px', lg: '25px' }}
      bgColor="#252964"
      flexDirection="column"
      pos="relative"
    >
      {isAvailableOn && (
        <Box
          py="15px"
          px={{ base: '15px', lg: '30px' }}
          bg="#03062C"
          w="full"
          bottom={{ base: '80px', lg: '85px' }}
          left="0"
          pos="absolute"
        >
          <Text
            fontSize="16px"
            mb="10px"
            color="#fff"
            fontFamily="FedraSansStd-medium"
          >
            Available on
          </Text>
          <Box
            display="flex"
            gridGap={{ base: '10px', lg: '15px' }}
            justifyContent="space-between"
          >
            {cardData?.musicAvailableIn.map((item, index) => {
              let imageSource = '';
              if (item.source === 'soundcloud') {
                imageSource = `${config.imgPath}/d/46272/1667302260-download-soundcloud.png`;
              } else if (item.source === 'googleplaymusic') {
                imageSource = `${config.imgPath}/d/46272/1667302255-download-googleplaymusic.png`;
              } else if (item.source === 'applemusic') {
                imageSource = `${config.imgPath}/d/46272/1667302278-download-applemusic.png`;
              } else if (item.source === 'spotify') {
                imageSource = `${config.imgPath}/d/46272/1667302274-download-spotify.png`;
              } else if (item.source === 'jiosaavn') {
                imageSource = `${config.imgPath}/d/46272/1667302266-download-jio.png`;
              } else if (item.source === 'amazonmusic') {
                imageSource = `${config.imgPath}/d/46272/1667302270-download-amazonmusic.png`;
              } else {
                imageSource = `${config.imgPath}/d/46272/1667302282-download-yt.png`;
              }

              return (
                <Box key={index}>
                  <Link
                    href={item.embedUrl}
                    target="_blank"
                    title={item.source}
                  >
                    <LazyLoadImageComponent
                      height="2em"
                      width="2em"
                      src={imageSource}
                    />
                  </Link>
                </Box>
              );
            })}
            <Box
              pos="absolute"
              bottom={{ base: '-10px', lg: '-8px' }}
              right={{ base: '30px', lg: '40px' }}
            >
              <svg
                width="15"
                height="13"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.23205 12C8.46225 13.3333 6.53775 13.3333 5.76795 12L1.00481 3.75C0.235009 2.41667 1.19726 0.750001 2.73686 0.750001L12.2631 0.75C13.8027 0.75 14.765 2.41667 13.9952 3.75L9.23205 12Z"
                  fill="#03062C"
                />
              </svg>
            </Box>
          </Box>
        </Box>
      )}
      <Box width="100%" height="100%">
        <Box bgColor="#fff" borderRadius="5px" height="144px" mb="20px">
          <embed
            type="video/webm"
            src={cardData.musicEmbed}
            width="100%"
            height="144"
          />
        </Box>
        <Heading as="h2" fontSize="26px" color="#FFF7E9" pb="8px">
          {cardData?.title}
        </Heading>
        <Box maxH="61px" overflow="hidden">
          <StructuredTextParser
            str={render(cardData.description)}
            theme="light"
            mt="5px"
          />
        </Box>
      </Box>

      <Flex justifyContent="space-between" pt="10px">
        <Box display="flex" alignItems="center">
          <Link href="" mr="10px" onClick={handleClick} cursor="pointer">
            <LikesSvgIcon isActive={meLiked} />
          </Link>
          {likeCount > likeCountLimit && (
            <Box
              as="span"
              fontWeight="600"
              color="#FFA735"
              fontFamily="FedraSansStd-book"
            >
              {numFormatter(likeCount)}
            </Box>
          )}
        </Box>

        <Box display="flex" gridGap="20px" pos="relative">
          {cardData?.downloadLink && (
            <NextLink href={cardData?.downloadLink} passHref legacyBehavior>
              <Link
                onClick={e => {
                  // download(cardData?.downloadLink);
                  dataLayerPush('download');
                }}
              >
                <DownloadSvgIcon />
              </Link>
            </NextLink>
          )}
          <Link onClick={handlerAvailableOn}>
            <MoreSvgIcon isActive={isAvailableOn} />
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default SongsCard;