/* Built In Imports */
import { useRef, useState } from 'react';

/* External Imports */
import { Box, Button } from '@chakra-ui/react';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
/* Services */

/**
 * Renders the MsrEventScheduleImageCardGroup component
 * @param {Object} sectionContent - Data for section
 * @returns {ReactElement} MsrEventScheduleImageCardGroup Card component
 */
const MsrEventScheduleImageCardGroup = ({ sectionContent }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef();

  const objArrows = {
    pos: 'absolute',
    top: '240px',
    zIndex: '10',
    cursor: 'pointer',
    bgSize: '10px',
    w: '46px',
    h: '46px',
    boxShadow: '0 0 7px 0 rgb(0 0 0 / 13%)',
    bgPos: 'center',
    bgRepeat: 'no-repeat',
    color: 'transparent',
    bgColor: '#fff',
    borderRadius: '50%'
  };

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        {...objArrows}
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right="15px"
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      ></Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        {...objArrows}
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left="15px"
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      />
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 11000,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => setSlideIndex(next),
    // beforeChange: (current, next) => setSlideIndex(next)
  };

  const handleOnClick = index => {
    sliderRef.current.slickGoTo(index);
    // consoleLog('sliderRef', sliderRef, index);
  };

  return (
    <Box
      mx="auto"
      maxW="1140px"
      w="full"
      pos="relative"
      display="flex"
      py="20px"
      flexDir={{ base: 'column', xl: 'row' }}
    >
      <Box
        order={{ base: 2, xl: 1 }}
        maxW={{ base: '100%', xl: '877px' }}
        height="100%"
        mx={{ base: '15px', xl: '24px' }}
      >
        <Slider ref={sliderRef} {...settings} m="0">
          {sectionContent?.scheduleCards?.map((card, index) => {
            return (
              <Box
                overflow="hidden"
                width="100%"
                display="flex"
                flexWrap="wrap"
              >
                <LazyLoadImageComponent
                  src={card.image?.url}
                  alt={card.image?.alt}
                  title={card.image?.title}
                  width={{ base: '100%', xl: '879px' }}
                  height={{ base: '259px', xl: '585px' }}
                  objectFit="cover"
                />
              </Box>
            );
          })}
        </Slider>
      </Box>

      <Box
        order={{ base: 1, xl: 2 }}
        bg="#252964"
        width={{ base: '100%', xl: '238px' }}
        px="20px"
        textAlign="center"
        flexDirection={{ base: 'row', xl: 'column' }}
        display="flex"
        className="MsrEventScheduleImageCardGroup-menu"
        justifyContent="center"
        mb={{ base: '24px', xl: '0' }}
      >
        {sectionContent?.scheduleCards?.map((card, index) => {
          return (
            <Button
              className="item"
              bg={index === slideIndex ? '#FFA735' : 'none'}
              fontSize={{ base: '12px', xl: '24px' }}
              color={index === slideIndex ? '#000000' : '#C1C3DD'}
              border="none"
              _hover={{
                background: '#FFA735',
                color: '#000000',
                fontWeight: '600',
              }}
              fontFamily="FedraSansStd-book"
              fontWeight={index === slideIndex ? '600' : 'normal'}
              mt={{ base: '11px', lg: '0px' }}
              mb={{ base: '11px', lg: '25px' }}
              onClick={e => {
                handleOnClick(index);
              }}
            >
              {card.time}
            </Button>
          );
        })}
      </Box>

      <style>
        {`
          .slick-list {
            margin: 0px;
          }
          @media(max-width:1024px) {
            .MsrEventScheduleImageCardGroup-menu {
            width: 100%;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            padding-left: 80%;
           
          }
           .MsrEventScheduleImageCardGroup-menu button {
            scroll-snap-align: start;
            margin: 11px;
            padding:10px 40px;
            
           }
          }
          @supports() {
            width: 100%;
            overflow-x: scroll;
            scroll-snap-type: x mandatory
          }
         
        `}
      </style>
    </Box>
  );
};

export default MsrEventScheduleImageCardGroup;
