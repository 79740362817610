/* Built In Imports */
import { Box } from '@chakra-ui/react';

/* External Imports */
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the EventScheduleDesc component.
 *
 * @param sectionContent.sectionContent
 * @param {Array} sectionContent - Content from api.
 * @param {string} lang - Current User Language.
 * @param sectionContent.lang
 * @returns {ReactElement} EventScheduleDesc Component.
 */
const EventScheduleDesc = ({ sectionContent, lang }) => {
  return (
    <MidContainer w="786px">
      <Box className="scheDesc">
        <StructuredTextParser
          str={render(sectionContent.body)}
          region={''}
          lang={lang}
          textAlign={sectionContent.style}
          maxW="100%"
          theme="light"
          m="0"
      />
        <style>
          {`
          .scheDesc p {
            margin-bottom: 10px; 
            margin-top:5px;
          }
        `}
        </style>
      </Box>
    </MidContainer>
  );
};

export default EventScheduleDesc;
