/* Built In Imports */

/* External Imports */
import { Box, SimpleGrid } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import EbookCard from '@msr/components/Downloads/EbookCard';
import Greetings from '@msr/components/Downloads/Greetings';
import SongsCard from '@msr/components/Downloads/SongsCard';
import VideosCard from '@msr/components/Downloads/VideosCard';
import Wallpapers from '@msr/components/Downloads/Wallpapers';

/* Services */

/**
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent
 * @param sectionContent.lang
 * @param sectionContent.pType
 * @returns
 */
const MsrDownloadsGroup = ({ sectionContent, lang, pType }) => {
  return (
    <Box>
      <SimpleGrid
        gridRowGap="10px"
        maxW="1140px"
        flexWrap="wrap"
        px={{ base: 4, md: 6, xl: 0 }}
        w="100%"
        columns={{
          base: 1,
          sm: pType === 'shiva-videos' || pType === 'shiva-ebooks' ? 1 : 2,
          lg:
            pType === 'mahashivratri-wishes-greetings-images'
              ? 2
              : pType === 'shiva-videos' || pType === 'shiva-ebooks'
              ? 1
              : 2,
          xl:
            pType === 'mahashivratri-wishes-greetings-images'
              ? 3
              : pType === 'shiva-videos' || pType === 'shiva-ebooks'
              ? 1
              : 2,
        }}
        spacing="15px"
        mx="auto"
      >
        {sectionContent.downloadCards?.map((card, i) => {
          switch (card?.downloadType) {
            case 'greeting':
              return <Greetings cardData={card} lang={lang} />;
            case 'video':
              return <VideosCard cardData={card} lang={lang} />;
            case 'music':
              return <SongsCard cardData={card} lang={lang} />;
            case 'wallpaper':
              return <Wallpapers cardData={card} lang={lang} />;
            case 'ebook':
              return <EbookCard cardData={card} />;
          }
        })}
      </SimpleGrid>
    </Box>
  );
};

export default MsrDownloadsGroup;
