/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the LiveStreamWatchLive component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} LiveStreamWatchLive Component.
 */

const LiveStreamWatchLive = ({ sectionContent }) => {
  return (
    <MidContainer w={{ base: '250px', md: '586px' }}>
      <Box
        as="h6"
        fontWeight="500"
        fontSize={{ base: '18px' }}
        color="white.50"
        p={{ base: '0 0 25px 0', md: '5px 0 25px 0' }}
        fontFamily="FedraSansStd-book"
        textAlign="center"
        px={{ base: '10px', md: 0 }}
      >
        {sectionContent?.text}
      </Box>
    </MidContainer>
  );
};

export default LiveStreamWatchLive;
