/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import YouTube from 'react-youtube';

/* Internal Imports */
import { getEmbedUrl } from '@components/Utility/utils';

/**
 * Renders the Top Banner Video component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - content from api
 * @param {object} language - language
 * @param sectionContent.language
 * @returns {ReactElement} - Top Banner Video Component
 */
const TopBannerVideo = ({ sectionContent, language }) => {
  const [vidVis, setVidVis] = useState(false);
  const [opts, setOpts] = useState({});

  const onReady = event => {
    event.target.playVideo();
  };

  useEffect(() => {
    setOpts({
      height: document.getElementById('vid-top')?.clientHeight,
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global?.window]);

  return (
    <Box
      // backgroundImage={sectionContent?.image?.url}
      backgroundImage={{
        md: sectionContent?.image?.url,
        base: sectionContent?.mobileImage?.url || sectionContent?.image?.url,
      }}
      backgroundPosition="center top"
      backgroundColor="#000"
      backgroundRepeat="no-repeat"
      width="100%"
      position="relative"
      color="#FFF7E9"
      fontSize="40px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      py={{ base: '50px', md: '0' }}
      cursor="pointer"
      onClick={e => setVidVis(!vidVis)}
      height={{ base: '250px', sm: '430px', md: '500px', lg: '75vh' }}
      backgroundSize={{ base: '100%', md: 'cover' }}
      id="vid-top"
    >
      {' '}
      {vidVis && (
        <Box maxW="100%" w="full">
          <YouTube
            videoId={getEmbedUrl(sectionContent?.videoUrl)}
            onReady={onReady}
            opts={opts}
          />
          {/* <Box
            as="iframe"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
            width="100%"
            height={{ base: '300px', sm: '430px', md: '500px', lg: '75vh' }}
            src={`https://www.youtube.com/embed/${getEmbedUrl(
              sectionContent?.videoUrl
            )}?autoplay=1`}
          /> */}
        </Box>
      )}
    </Box>
  );
};

export default TopBannerVideo;
