/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import DownloadSvgIcon from '@msr/components/Buttons/DownloadSvgIcon';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/**
 * 
 * @param {Object} cardData 
 * @returns 
 */
const VideosCard = ({ cardData }) => {
  const dataLayerPush = eType => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'msr_engagements',
      product_category: 'videos', //e.g. wallpaper or wishes or songs or videos or ebooks
      engagement_type: eType, //e.g. download or share or like
      title: cardData?.title
    });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const clickHandler = async (url) => {
    dataLayerPush('download');
    openInNewTab(url);
  };

  return (
    <Box
      bgColor="#252964"
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      className="shivaVideosSection"
    >
      <Box
        backgroundImage={cardData?.thumbnailImage?.url}
        h={{ base: '196px', md: '100%' }}
        width={{ base: '100%', md: '27%', lg: '24%', xl: '43%' }}
        flexGrow="0"
        flexShrink="0"
        flexBasis={{ base: '180px', md: 'auto' }}
        backgroundSize="cover"
        backgroundPosition="center"
      />
      <Box p={{ base: '20px', md: '40px' }} width="100%">
        <Flex flexDirection="column">
          <Flex mt="auto" w="full" justifyContent="space-between">
            <Text
              fontSize={{ base: '18px', md: '22px', xl: '26px' }}
              color="#FFF7E9"
              display="flex"
              fontWeight="600"
              fontFamily="FedraSerifAStdBook"
            >
              {cardData?.title}
            </Text>

            <Box
              fontSize="16px"
              width="82px"
              display={{ base: 'none', md: 'flex' }}
              justifyContent="end"
            >
              <Link
              // href={cardData?.downloadLink}
              // isExternal
                onClick={e => clickHandler(cardData?.downloadLink)}
              >
                <DownloadSvgIcon />
              </Link>
            </Box>
          </Flex>
          <Box pb={{ base: '20px', md: 0 }}>
            <StructuredTextParser
              str={render(cardData.description)}
              my="0"
              theme="light"
            />
          </Box>
        </Flex>
        <Flex
          mt="auto"
          justifyContent="end"
          alignItems="center"
          display={{ md: 'none' }}
        >
          <Box fontSize="16px" display="flex" justifyContent="end">
            <Link
              // href={cardData?.downloadLink}
              // isExternal
              onClick={e => clickHandler(cardData?.downloadLink)}
            >
              <DownloadSvgIcon />
            </Link>
          </Box>
        </Flex>
      </Box>
      <style>
        {` .shivaVideosSection  .structured-content-light p {
              margin: 5px auto;
        }
        `}{' '}
      </style>
    </Box>
  );
};

export default VideosCard;
