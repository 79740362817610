/* Built In Imports */
import { Box } from '@chakra-ui/react';

/* External Imports */
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the LiveStreamList component.
 *
 * @param sectionContent.sectionContent
 * @param {Array} sectionContent - Content from api.
 * @param {string} lang - Current User Language.
 * @param sectionContent.lang
 * @returns {ReactElement} PartnerTitle Component.
 */
const PartnerTitle = ({ sectionContent, lang }) => {
  return (
    <MidContainer w="786px">
      <Box className="partner-title">
        <StructuredTextParser
          str={render(sectionContent.body)}
          region={''}
          lang={lang}
          textAlign={sectionContent.style}
          maxW="100%"
          theme="light"
        />
        <style>
          {`
          .partner-title h2 {
            font-size: 26px;
            margin-top: 40px;
            margin-bottom: 0;
            font-family: FedraSerifAStdBook;
            font-weight: 350;
            max-width: 100%;
          }
        `}
        </style>
      </Box>
    </MidContainer>
  );
};

export default PartnerTitle;
