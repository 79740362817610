/* Built In Imports */
import { Box } from '@chakra-ui/react';

/* External Imports */
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the LiveStreamList component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} LiveStreamList Component.
 */

const LiveStreamList = ({ sectionContent, lang }) => {
  return (
    <MidContainer w="786px">
      <Box className="globalLangList">
        <StructuredTextParser
          str={render(sectionContent.body)}
          region={''}
          lang={lang}
          textAlign={sectionContent.style}
          maxW="100%"
          mt={{ base: '0', md: '15px' }}
        />
      </Box>
      <style>
        {`
        .globalLangList {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
        
          .globalLangList ul {
            display: flex;
            justify-content: center;
            list-style: none;
            flex-wrap:nowrap
          }
          .globalLangList ul li {
            border-right: 1px solid #fff7e9;
            margin: 0;
            flex-basis:content;
          }
     
          .globalLangList ul li:last-child {
            border: none;
          }

          .globalLangList ul li a {
            color: #fff7e9;
            padding: 0 13px;
            white-space: nowrap;
            display: inline;
            text-decoration: none
          }
          .globalLangList ul li p {
            margin: 0px;
            flex: auto;
          }

          @media (max-width:1199px) {
            .globalLangList ul {
              display: flex;
              justify-content: center;
              list-style: none;
              flex-wrap:wrap;
              margin-bottom: 0;
            }
            .globalLangList ul li {
              margin: 0 0 15px 0;
            }
          }


        `}
      </style>
    </MidContainer>
  );
};

export default LiveStreamList;
