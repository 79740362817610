/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports*/
/* Components */
import MediaEmbed from '@msr/components/Embeds/MediaEmbed';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/**
 * Renders the MsrPerformingArtistsCardZigzagGroup Card component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @param sectionContent.lang
 * @returns {ReactElement} MsrPerformingArtistsCardZigzagGroup Card component
 */
const MsrPerformingArtistsCardZigzagGroup = ({ sectionContent, lang }) => {
  return (
    <Box m={{ base: '0 auto', md: '30px auto' }}>
      {sectionContent.cards.map((data, index) => {
        const isEvenIndex = index % 2 == 1;
        const flexDirection = isEvenIndex ? 'row-reverse' : 'row';
        const textAlign = isEvenIndex ? 'left' : 'right';
        const float = isEvenIndex ? 'right' : 'left';
        const embedObj = {
          embedUrl: data?.videoUrl,
          source: 'youtube',
        };

        return (
          <Box
            display="flex"
            justifyContent="center"
            key={nanoid()}
            mb={{ base: 12, md: '4.5em' }}
            w={{ base: 'auto', lg: '1140px' }}
            flexDirection={{ base: 'column-reverse', md: flexDirection }}
            position="relative"
            m={{ base: '0 16px 35px', md: '0 0 40px 0' }}
            overflow="hidden"
            sx={{
              _last: {
                mb: '10px',
              },
            }}
          >
            <Box
              className="description"
              w={{ base: '100%', md: '45%' }}
              color={'black'}
              textAlign="right"
              px={{ base: '0', sm: '16px' }}
            >
              {data.featuredText != '' && (
                <>
                  <Box
                    bgColor="#00A542"
                    borderRadius="10px"
                    width="202px"
                    height="41px"
                    fontSize={{ base: '14px', md: '18px' }}
                    fontFamily={'FedraSansStd-book'}
                    textAlign="center"
                    color="#ffffff"
                    p="6px 0"
                    m="0 auto 15px auto"
                    pos={{ base: 'absolute', md: 'static' }}
                    top="0"
                    left="-10px"
                    zIndex="1"
                  >
                    <Box
                      as="span"
                      w="10px"
                      h="10px"
                      position="relative"
                      left="-6px"
                      display="inline-block"
                      bgColor={'#ffffff'}
                      borderRadius="10px"
                    />
                    {data.featuredText}
                  </Box>
                </>
              )}
              <Box
                as="h3"
                fontSize="26px"
                fontFamily={'FedraSansStd-book'}
                color={{ base: '#AAAAAA', md: '#FFF7E9' }}
                mb={{ base: '0', md: '20px' }}
                mt={{ base: '25px', md: '0' }}
                fontWeight="bold"
                textAlign="center"
              >
                {data.date}
              </Box>
              <Box
                as="h3"
                fontFamily={'FedraSansStd-A-medium'}
                fontSize="26px"
                // textAlign={textAlign}
                textAlign={{ base: 'center' }}
                color="#FFA735"
                lineHeight={'1.61'}
                mb={{ base: '0', md: '5%' }}
                mt={{ base: '15px', md: '0' }}
              >
                {' '}
                {data.artistName}
                <Box
                  as="p"
                  fontFamily={'FedraSansStd-book'}
                  fontSize={{ base: '16px', lg: '16px' }}
                  color="#ffffff"
                  mt={{ base: '2px', md: '0' }}
                >
                  {data.artistType}
                </Box>
              </Box>
              <Box
                as="h4"
                fontSize={'16px'}
                pb={2}
                fontFamily={'FedraSansStd-book'}
                // textAlign="left"
                textAlign={{ base: 'center', md: textAlign }}
                className="structuredText"
              >
                <StructuredTextParser
                  str={render(data.description)}
                  region={''}
                  lang={lang}
                  color="#AAAAAA"
                  // textAlign={textAlign}
                  textAlign={{ base: 'center', md: 'center' }}
                  sx={{ p: { color: '#AAAAAA' } }}
                />
              </Box>
            </Box>

            <Box
              w={{ base: '100%', md: '55%' }}
              px={{ base: '0', sm: '16px' }}
              textAlign={{ base: 'center', sm: 'left' }}
            >
              <MediaEmbed sectionContent={embedObj} margin="0" />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MsrPerformingArtistsCardZigzagGroup;
