/* Built In Imports */
/* External Imports */
import { Heading } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the SadhanaNote component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} SadhanaNote Component.
 */

const SadhanaNote = ({ sectionContent }) => {
  return (
    <MidContainer w="586px">
      <Heading
        as="h6"
        fontWeight="500"
        fontSize={{ base: '18px' }}
        color="#FFA735"
        pb={{ base: '30px', md: '40px' }}
        fontFamily="FedraSerifAStdBook"
        textAlign="center"
        mt="30px"
        px={{ base: '10px', md: 0 }}
      >
        {sectionContent?.text}
      </Heading>
    </MidContainer>
  );
};

export default SadhanaNote;
