import { useEffect, useRef, useState } from 'react';

export function useVisibleTab(ref) {
  const [visibleTab, setVisibleTab] = useState();
  const observerRef = useRef(null);

  let options = {
    rootMargin: '-10% 0% -90% 0%',
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisibleTab(entry.target.id);
        }
      });
    }, options);
  }, []);

  useEffect(() => {
    ref.current?.forEach(entry => observerRef.current.observe(entry));

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return visibleTab;
}
