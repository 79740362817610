/* Built In Imports */
/* External Imports */
import { Heading } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the LiveStreamTitle component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} LiveStreamTitle Component.
 */

const LiveStreamTitle = ({ sectionContent }) => {
  return (
    <MidContainer w="586px">
      <Heading
        as="h1"
        fontSize={{ base: '26px', lg: '40px' }}
        color="white.50"
        pb="0"
        fontFamily="FedraSerifAStdBook"
        textAlign={{ base: 'center', md: 'center' }}
        mt={{ base: '40px', md: '70px' }}
        mb="0"
        px={{ base: '15px', md: 0 }}
        maxW={{ base: '360px', md: 'inherit' }}
        mx={{ base: 'auto', md: 'inherit' }}
      >
        {sectionContent?.text}
      </Heading>
    </MidContainer>
  );
};

export default LiveStreamTitle;
