/* Built In Imports */
import { memo, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Lightbox from 'react-image-lightbox';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import ProgressiveImage from '@components/UI/ProgressiveImage';
import config from '@config';

/* Services */
/* Styles */
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Render Slider Common Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent
 * @param sectionContent.isLeftMenu
 * @param sectionContent.showArrows
 * @param sectionContent.className
 * @returns {ReactElement} Slider Common Component
 */
const SliderCommon = ({
  sectionContent,
  isLeftMenu,
  showArrows,
  className,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openImage = index => {
    setPhotoIndex(index);
    setOpen(true);
  };

  const images = sectionContent?.images.map(function (image) {
    return image.url;
  });

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        textAlign={'center'}
        top="30%"
        zIndex="10"
        cursor="pointer"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="54px"
        h="54px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right={{ xl: '-30px', base: '3px' }}
        bgSize="13px"
        onClick={onClick}
        boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
      ></Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="30%"
        zIndex="10"
        cursor="pointer"
        bgSize="13px"
        bgColor="#fff"
        border="0"
        borderRadius="100%"
        w="54px"
        h="54px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left={{ xl: '-30px', base: '3px' }}
        onClick={onClick}
        boxShadow="rgba(0, 0, 0, 0.3) 0 0 15px 0"
      />
    );
  };

  const settingsSmall = {
    lazyLoad: false,
    centerMode: true,
    slidesToShow: isLeftMenu ? 3 : sectionContent?.images?.length,
    slidesToScroll: isLeftMenu ? 3 : sectionContent?.images?.length,
    nextArrow: showArrows ? <NextArrow /> : <></>,
    prevArrow: showArrows ? <PrevArrow /> : <></>,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1170,
      },
      {
        breakpoint: 834,
      },
      {
        breakpoint: 480,
      },
    ],
  };

  return (
    <>
      <Box
        p={{ sm: '0', xl: '0 30px' }}
        className={className}
        id="slider-common"
        w="100%"
        textAlign="center"
      >
        <Slider {...settingsSmall}>
          {sectionContent?.images?.map((item, index) => (
            <Box key={nanoid()} outline="none" textAlign="center" mx="auto">
              <Box
                m="0"
                overflow="hidden"
                cursor="pointer"
                transition="all 0.5s linear"
                onClick={() => openImage(index)}
              >
                <ProgressiveImage
                  transition="all 0.05s linear"
                  _hover={{
                    transform: 'scale(1.25)',
                  }}
                  title={item?.title}
                  src={item.url}
                  alt={item.alt}
                  objectFit={'cover'}
                />
              </Box>
            </Box>
          ))}
        </Slider>
        <style global jsx>
          {`
            .copartners-logos .slick-track {
              display: flex;
              justify-content: center;
            }
            .copartners-logos .slick-slide {
              width: 325px !important;
              margin: 0 5px;
            }
            .support-partners-logo .slick-track {
              display: flex;
              justify-content: center;
              padding: 0 50px;
            }
            .support-partners-logo .slick-slide {
              width: 201px !important;
              margin: 0 5px;
            }
            @media (max-width: 768px) {
              .copartners-logos .slick-track {
                padding: 0 100px;
              }
              .support-partners-logo .slick-track {
                padding: 0 20px;
              }
              .support-partners-logo .slick-slide {
                margin: 0 5px;
              }
            }
            @media (max-width: 480px) {
              .copartners-logos .slick-track {
                flex-direction: column;
                padding: 0 15px;
              }
              .copartners-logos .slick-slide {
                width: 212px !important;
                margin: 0 auto;
              }
              .support-partners-logo .slick-track {
                flex-wrap: wrap;
                padding: 0 15px;
              }
              .support-partners-logo .slick-slide {
                width: 105px !important;
                margin: 0 5px;
              }
            }
            .ril-next-button {
              position: absolute;
              top: 10%;
            }
            .ril-prev-button {
              position: absolute;
              top: 10%;
            }
            .ril__outer {
              background-color: rgba(0, 0, 0);
            }
          `}
        </style>
      </Box>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          animationOnKeyInput="true"
          animationDuration={500}
          enableZoom={false}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};
export default memo(SliderCommon);
