/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import MidContainer from '@components/Layout/MidContainer';
import { nanoid } from 'nanoid';

/* Internal Imports */
import MsrEventScheduleCard from './MsrEventScheduleCard';

/* Services */

/**
 *
 * @param {object} content
 * @returns
 */

const MsrEventScheduleCardGroup = ({ sectionContent, lang }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      h="100%"
      w={{ base: '100%', md: '90%', lg: '70%', xl: '48%' }}
      alignItems="center"
      mx="auto"
      // _last={{ marginBottom: '60px' }}
    >
      {sectionContent?.scheduleCards?.length
        ? sectionContent?.scheduleCards?.map((card, index) => {
            return (
              <MidContainer>
                <MsrEventScheduleCard
                  card={card}
                  lang={lang}
                  key={nanoid(4)}
                  index={index}
                  sectionContent={sectionContent}
                />
              </MidContainer>
            );
          })
        : null}
    </Box>
  );
};

export default MsrEventScheduleCardGroup;
