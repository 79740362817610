/* Built In Imports */
import { React, useEffect, useRef, useState } from 'react';

/* External Imports */
import { Box, Link, Tab, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { useVisibleTab } from '@landing_pages/msr/hooks/useVisibleTab';
import MidContainer from '@Layout/MidContainer';
import SectionUI from '@msr/Common/SectionUI';

/* Services */

/**
 *
 * @param sectionContent.section
 * @param {object} sectionContent
 * @param sectionContent.sectionContent
 * @param sectionContent.lang
 * @returns
 */
const StickyTabGroup = ({ section, sectionContent, lang }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabRefs = useRef([]);
  const visibleTab = useVisibleTab(tabRefs);

  const createId = text => {
    return text.toLowerCase().replace(/ /g, '-');
  };

  let tabIds = {},
    tabIndices = {};
  sectionContent.tabContent?.map((tabsItem, i) => {
    const tabId = createId(tabsItem.title);
    tabIds[i] = tabId;
    tabIndices[tabId] = i;
  });

  useEffect(() => {
    if (visibleTab) {
      setTabIndex(tabIndices[visibleTab]);
    }
  }, [visibleTab]);

  const handleTabsChange = index => {
    setTabIndex(index);
    const yOffset = -60;
    const y =
      tabRefs.current[index]?.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <MidContainer w={'1064'}>
      <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
        <TabList
          position="sticky"
          zIndex={2}
          top={0}
          bg="blue.600"
          px={{ base: '16px', md: '30px' }}
          py={{ base: '9px', md: '13px' }}
          fontFamily="FedraSansStd-medium"
          borderRadius="5px"
          justifyContent="space-between"
        >
          {sectionContent.tabContent?.map((tabsItem, i) => {
            return (
              <Tab
                key={nanoid(3)}
                _selected={{
                  color: 'black',
                  bg: 'yellow.100',
                  borderRadius: '5px',
                }}
                color="blue.200"
                fontSize={{ base: '12px', md: '16px' }}
                lineHeight="20px"
                p={{ base: '0', md: '16px' }}
                height={{ base: '42px', md: '58px' }}
                ariaSelected={i === tabIndex}
              >
                <Link
                  href={`#${createId(tabsItem.title)}`}
                  _hover={{
                    textDecoration: 'none',
                  }}
                >
                  {tabsItem.title}
                </Link>
              </Tab>
            );
          })}
        </TabList>

        <TabPanels>
          {sectionContent.tabContent?.map((tabsItem, i) => {
            return (
              <Box as="section" className="sadhana-section">
                <Box
                  mx="16px"
                  id={`${createId(tabsItem.title)}`}
                  ref={r => (tabRefs.current[i] = r)}
                >
                  <MidContainer w={'770'}>
                    {tabsItem.content.map((secCon, j) => {
                      return (
                        <>
                          {i === 0 && j === 0 ? (
                            <></>
                          ) : (
                            <SectionUI
                              key={j}
                              section={section}
                              sectionContent={secCon}
                              region={''}
                              language={lang}
                            />
                          )}
                        </>
                      );
                    })}
                  </MidContainer>
                </Box>
              </Box>
            );
          })}
        </TabPanels>
      </Tabs>
    </MidContainer>
  );
};

export default StickyTabGroup;
