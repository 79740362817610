/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import parse from 'html-react-parser';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders the Quote Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - data
 * @returns {ReactElement} Quote component
 */
const Quote = ({ sectionContent }) => {
  return (
    <Box
      maxW={{ base: '90%', md: '660px' }}
      mt={{ base: '15px', md: '30px' }}
      mx="auto"
      fontFamily="FedraSansStd-book-i"
      fontSize={{ base: 16, md: '20px' }}
      fontWeight="400"
      py={{ base: '20px', md: '25px' }}
      px={{ base: '15px', md: '110px' }}
      fontStyle="italic"
      lineHeight="1.5"
      textAlign="center"
      color="#FFF7E9"
      marginBottom="40px"
      bgColor="#424786"
      display="flex"
    >
      <Box as="span">
        {' '}
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.9502 2.52015C3.77966 1.59325 4.57698 0.939932 5.34215 0.545898L5.3893 0.545898L6.72673 1.71575L6.72673 2.26699C6.07686 2.86874 5.54805 3.57866 5.16639 4.3617C4.68843 5.27226 4.46553 6.40332 4.46553 7.76305C4.45656 8.18793 4.50994 8.61192 4.62413 9.02273C4.72571 9.34008 4.86447 9.64552 5.03779 9.9333C5.23368 10.2287 5.44842 10.5123 5.68079 10.7826C5.98846 11.1393 6.25571 11.526 6.4781 11.9361C6.66313 12.3427 6.74958 12.7837 6.73102 13.2264C6.73877 13.6789 6.61727 14.1249 6.37951 14.5167C6.14242 14.8873 5.80672 15.1916 5.40644 15.3987C4.98498 15.6121 4.51414 15.7216 4.03687 15.7172L4.02186 15.7172C3.25945 15.7122 2.52647 15.4362 1.96642 14.9434C1.32631 14.3796 0.83439 13.6802 0.530391 12.9018C0.179543 12.0407 0.00142735 11.1245 0.00528145 10.2007C-0.00741476 8.73184 0.283911 7.27494 0.862609 5.91333C1.35953 4.68138 2.06471 3.53518 2.9502 2.52015ZM12.8095 2.52015C13.6389 1.59325 14.4341 0.939931 15.2164 0.545897L15.2464 0.545897L16.601 1.71575L16.601 2.26699C15.9465 2.86493 15.417 3.57574 15.0407 4.36169C14.5627 5.27226 14.3227 6.40332 14.3227 7.76304C14.3189 8.18872 14.378 8.61277 14.4984 9.02273C14.5924 9.34267 14.7316 9.64898 14.9121 9.93329C15.0728 10.1762 15.2786 10.4641 15.5336 10.7826C15.842 11.1388 16.1093 11.5255 16.3309 11.9361C16.5233 12.3409 16.6157 12.7823 16.601 13.2264C16.6059 13.6799 16.4807 14.1261 16.2388 14.5167C16.0072 14.8907 15.671 15.1961 15.2679 15.3987C14.8471 15.6138 14.3758 15.7234 13.8983 15.7172L13.8811 15.7172C13.1228 15.7142 12.3938 15.4377 11.8407 14.9434C11.1919 14.3842 10.6948 13.6837 10.3918 12.9018C10.0448 12.0401 9.87173 11.1237 9.8817 10.2007C9.86463 8.73263 10.1516 7.27571 10.7262 5.91333C11.236 4.68819 11.9384 3.54404 12.8095 2.52015Z"
            fill="#FFA735"
          />
        </svg>
      </Box>
      {parse(sectionContent?.quote?.replace(/\n/g, '<br />'))}
      <Box display="flex" alignItems="end">
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.6475 13.5951C12.818 14.522 12.0207 15.1753 11.2555 15.5693L11.2084 15.5693L9.87093 14.3995L9.87093 13.8482C10.5208 13.2465 11.0496 12.5366 11.4313 11.7535C11.9092 10.843 12.1321 9.71191 12.1321 8.35219C12.1411 7.92731 12.0877 7.50332 11.9735 7.09251C11.8719 6.77516 11.7332 6.46971 11.5599 6.18194C11.364 5.88657 11.1492 5.60293 10.9169 5.33262C10.6092 4.97593 10.3419 4.58928 10.1196 4.17911C9.93453 3.77257 9.84807 3.33154 9.86664 2.8888C9.85888 2.4363 9.98038 1.9903 10.2181 1.59849C10.4552 1.22795 10.7909 0.923683 11.1912 0.716505C11.6127 0.503111 12.0835 0.393619 12.5608 0.398011L12.5758 0.398011C13.3382 0.403069 14.0712 0.679002 14.6312 1.17179C15.2713 1.73566 15.7633 2.43503 16.0673 3.21342C16.4181 4.07456 16.5962 4.99077 16.5924 5.91449C16.6051 7.3834 16.3137 8.84029 15.735 10.2019C15.2381 11.4338 14.5329 12.5801 13.6475 13.5951ZM3.78818 13.5951C2.95872 14.522 2.16355 15.1753 1.38124 15.5693L1.35123 15.5693L-0.0033475 14.3995L-0.00334755 13.8482C0.651167 13.2503 1.18064 12.5395 1.55699 11.7535C2.03495 10.843 2.275 9.71191 2.275 8.35219C2.27878 7.92652 2.21962 7.50246 2.09925 7.09251C2.00521 6.77257 1.86605 6.46625 1.68559 6.18194C1.52484 5.93899 1.31908 5.65112 1.06403 5.33262C0.755625 4.97645 0.488316 4.58972 0.266713 4.17911C0.0743626 3.77431 -0.0180236 3.33291 -0.00334855 2.8888C-0.00828671 2.43529 0.116965 1.98912 0.358874 1.59849C0.590497 1.2245 0.926634 0.919152 1.3298 0.716505C1.75059 0.501454 2.22189 0.391855 2.69938 0.398011L2.71652 0.398011C3.47488 0.401059 4.2039 0.677521 4.75696 1.17179C5.40577 1.73101 5.90289 2.43149 6.20585 3.21342C6.55286 4.07516 6.72593 4.99157 6.71596 5.91449C6.73302 7.3826 6.44606 8.83952 5.87149 10.2019C5.3617 11.427 4.65923 12.5712 3.78818 13.5951Z"
            fill="#FFA735"
          />
        </svg>
      </Box>
    </Box>
  );
};

export default Quote;
