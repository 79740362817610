/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { useCookies } from 'react-cookie';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import DownloadSvgIcon from '@msr/components/Buttons/DownloadSvgIcon';
import LikesSvgIcon from '@msr/components/Buttons/LikesSvgIcon';
import ShareSvgIcon from '@msr/components/Buttons/ShareSvgIcon';
import { downloadUrl, numFormatter } from '@msr/components/Utility/utils';

/* Services */
import { postLikes } from 'services/msrService';

/**
 *
 * @param cardData.cardData
 * @param {object} cardData
 * @param cardData.lang
 * @returns
 */
const Greetings = ({ cardData, lang }) => {
  const envName = config.ENV_NAME === 'production' ? '' : config.ENV_NAME + '_';
  const likeCookieName = `${envName}msr_likes_id`;
  const [likeCount, setLikeCount] = useState(cardData?.likestotalcount);
  // const [activeBtn, setActiveBtn] = useState('none');
  const [likesCookie, setCookie, removeCookie] = useCookies([likeCookieName]);
  const [meLiked, setMeLiked] = useState(false);
  const [mobAction, setMobAction] = useState(false);
  const likeCountLimit = 100;

  useEffect(() => {
    if (likesCookie[likeCookieName]?.indexOf(cardData?.uuid) > -1) {
      setMeLiked(true);
    }
    // consoleLog('meLiked', meLiked);
    if (meLiked && likeCount === 0) {
      setLikeCount(1);
    }
  }, [meLiked]);

  const handleClick = () => {
    postLikeFromAPI();
  };

  const postLikeFromAPI = async () => {
    const { contentType, uuid } = cardData;
    // consoleLog('postLikeFromAPI called', contentType, uuid);
    const response = await postLikes({
      uuid: uuid,
      action: meLiked ? 'remove' : 'add',
      contenttype: 'mahashivratri_shiva_section',
      language: lang || 'en',
    });
    const responseBody = await response;
    setLikeCount(responseBody?.likestotalcount);
    setMeLiked(!meLiked);
    // consoleLog('cookieData', likesCookie[likeCookieName], meLiked, likeCount, responseBody);
    let cookieData = likesCookie[likeCookieName];
    if (cookieData?.length && meLiked) {
      const index = cookieData.indexOf(uuid);

      if (index > -1) {
        // only splice array when item is found
        cookieData = cookieData.splice(index, 1);
        // consoleLog('index', likesCookie, index, cookieData);
      }
    } else {
      if (!cookieData) {
        cookieData = [];
      }
      cookieData.push(uuid);
    }

    // consoleLog('setCookie', likeCookieName, meLiked);
    // document.cookie = `${likeCookieName}=${cookieData} Path=/; domain==${config.COOKIE_DOMAIN} Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    setCookie(likeCookieName, cookieData, {
      path: '/',
      maxAge: 3600 * 24 * 365, // Expires after 1year
      sameSite: true,
      domain: config.COOKIE_DOMAIN,
      secure: true,
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'msr_engagements',
      product_category: 'wishes', //e.g. wallpaper or wishes or songs or videos or ebooks
      engagement_type: 'like', //e.g. download or share or like
      title: cardData?.title,
    });
  };

  const dataLayerPush = eType => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'msr_engagements',
      product_category: 'wishes', //e.g. wallpaper or wishes or songs or videos or ebooks
      engagement_type: eType, //e.g. download or share or like
      title: cardData?.title,
    });
  };

  const download = async url => {
    dataLayerPush('download');
    downloadUrl(url, `greeting${new Date().getMilliseconds()}`);
  };

  return (
    <Box display="flex" flexDirection="column" m="30px 0 0 0">
      <Box bgColor="#252964" width="100%">
        {mobAction && (
          <Box
            position="absolute"
            bgColor="#000000b3"
            zIndex="1"
            height="370px"
            maxWidth="370px"
            width="100%"
            display="flex"
            alignItems="center"
            placeContent="center"
            color="white"
            fontSize="16px"
            fontFamily="FedraSansStd-medium"
            padding="5%"
            textAlign="center"
          >
            Tap and hold to share image
          </Box>
        )}
        <Box
          height={{ base: '370px', lg: 'auto' }}
          position="relative"
          zIndex="1"
          opacity={mobAction ? '0.3' : '1'}
        >
          <LazyLoadImageComponent
            src={cardData?.greetingsImage?.url}
            alt={cardData?.greetingsImage?.alt}
            title={cardData?.greetingsImage?.title}
            height={{ base: '370px', lg: 'auto' }}
            width="100%"
            objectFit="cover"
          />
        </Box>
        <Flex justifyContent="space-between" p="10px" alignItems="center">
          <Text
            as="h2"
            fontSize={{ base: '16px', md: '18px' }}
            fontFamily="FedraSerifAStdBook"
            color="#fff"
            fontWeight="600"
          >
            {cardData?.title}
          </Text>

          <Box display="flex" gridGap="10px" alignItems="center">
            <Link mr="2px" onClick={handleClick} cursor="pointer">
              <LikesSvgIcon isActive={meLiked} />
            </Link>
            {likeCount > likeCountLimit && (
              <Box
                as="span"
                fontWeight="600"
                color="#FFA735"
                fontFamily="FedraSansStd-book"
                fontSize="12px"
                ml="-6px"
              >
                {numFormatter(likeCount)}
              </Box>
            )}
            <Button
              onClick={() => {
                setMobAction(!mobAction);
              }}
              d={{ base: 'block', md: 'none' }}
              background="none"
              border="none"
              p="0"
              mr="2px"
              cursor="pointer"
            >
              <ShareSvgIcon />
            </Button>
            <Link
              onClick={e => {
                download(cardData?.greetingsImage?.url);
              }}
              // onClick={e => {
              //   downloadUrl(cardData?.greetingsImage?.url);
              // }}
              // href={cardData?.greetingsImage?.url}
              // isExternal
              // onClick={e => dataLayerPush('download')}
            >
              <DownloadSvgIcon />
            </Link>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default Greetings;