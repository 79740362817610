/* Built In Imports */
/* External Imports */
import { Box, Heading, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */
/**
 *
 * @param root0
 * @param root0.post
 * @returns
 */
export const TestimonialCard = ({ post }) => {
  return (
    <Box
      background="white.50"
      boxShadow="0px 4px 25px rgba(0, 0, 0, 0.15)"
      borderRadius="10px"
      flexDir="column"
      alignItems="center"
      py={{ base: '20px', md: '32px' }}
      px={{ base: '8px', md: '28px' }}
      placeContent="center"
      maxW={{ base: '300px', sm: '348px', md: '315px', lg: '348px' }}
      margin="20px 10px 20px 10px"
      display={{ lg: 'flex', md: 'flex', base: 'block' }}
      textAlign="center"
    >
      <LazyLoadImageComponent
        src={post.photo?.url}
        alt={post.photo?.alt}
        pb="18px"
        width="138px"
        height="138px"
      />
      <Heading as="h3" color="#424786" fontSize="26px" lineHeight="33.15px">
        {post.userName}
      </Heading>
      <Heading as="h4">
        <StructuredTextParser
          str={render(post.userDescription)}
          region={''}
          lang={''}
          fontFamily="'FedraSansStd-medium'"
          fontStyle="normal"
          fontWeight="500"
          fontSize="16px"
          lineHeight="24px"
          textAlign="center"
        />
      </Heading>
      <Text as="p">
        <StructuredTextParser
          str={render(post.testimonialText)}
          region={''}
          lang={''}
          textAlign="center"
          fontFamily="FedraSansStd-book"
          fontSize="16px"
          lineHeight="24px"
        />
      </Text>
    </Box>
  );
};
